<template>
  <div id="directInvest_SignUp">
    <div class="container_directInvest_SignUp">
      <div class="container_mid">
        <h1 class="brand">
          <span>{{ $t("directInvest_SignUp.neuro") }}</span
          >{{ $t("directInvest_SignUp.leap") }}
        </h1>
        <div class="wrapper">
          <div class="directInvest_SignUpForm">
            
            <form
              id="directInvest_SignUpFormular"
              @submit.prevent="submitFormSign"
            >
            <!-- tab for personal information-->
              <div class="tab">
                <b-container>
            <b-row>
              <b-col class="gridTitle">
                  <span class="sectionTitle">
                    {{ $t("directInvest_SignUp.personal_information") }}
                  </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
                  <label>{{ $t("directInvest_SignUp.account") }} *</label>
                  <select
                    id="drpdwn_account_type"
                    name="drpdwn_account_type"
                    required
                  >
                    <option value="" disabled selected>{{ $t("directInvest_SignUp.placeholder") }}</option>
                    <option>
                      {{ $t("directInvest_SignUp.individual_acc") }}
                    </option>
                    <option>
                      {{ $t("directInvest_SignUp.custodial_acc") }}
                    </option>
                    <option>
                      {{ $t("directInvest_SignUp.coverdell_acc") }}
                    </option>
                  </select>
                </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="sectionInformation">
                    {{ $t("directInvest_SignUp.information_name") }}
                  </span>
                </b-col>
            </b-row>
            <b-row>
              <b-col sm>
                <label>{{ $t("directInvest_SignUp.firstname") }} *</label>
                  <input
                    id="txt_firstname"
                    type="text"
                    name="txt_firstname"
                    v-on:input="input_check_name('txt_firstname')"
                    required
                  />
                  </b-col>
              <b-col sm>
                <label>{{ $t("directInvest_SignUp.lastname") }} *</label>
                  <input
                    id="txt_lastname"
                    type="text"
                    name="txt_lastname"
                    v-on:input="input_check_name('txt_lastname')"
                    required
                  />
                  </b-col>
              <b-col sm>
                <label>{{ $t("directInvest_SignUp.suffix") }}</label>
                  <input
                    id="txt_suffix"
                    type="text"
                    name="txt_suffix"
                    />
                  </b-col>
            </b-row>
            <b-row>
              <b-col sm>
                <label>{{ $t("directInvest_SignUp.date_of_birth") }} *</label>
                  <input
                    id="date_of_birth"
                    type="date"
                    name="date_of_birth"
                    @change='showGuardianInput'
                    required
                  />
                  </b-col>
              <b-col sm><!-- SSN Format 001-01-0001-->
                  <label>{{ $t("directInvest_SignUp.socialSecNum") }} *</label>
                  <input
                    id="inp_socialSecNum"
                    type="number"
                    name="inp_socialSecNum"
                    required
                  />
                  </b-col>
              <b-col sm>
                <label>{{$t("directInvest_SignUp.socialSecNum_confirmation")}}*</label>
                  <input
                    id="inp_socialSecNumConfirm"
                    type="number"
                    name="inp_socialSecNumConfirm"
                    v-on:keyup="
                      confirm_SSN('inp_socialSecNum', 'inp_socialSecNumConfirm')"
                      required
                  />
                  </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
                <label>{{$t("directInvest_SignUp.government_issued_id")}}*</label>
                  <select
                    id="drpdwn_governmentID"
                    name="drpdwn_governmentID"
                    required
                  >
                    <option value="" disabled selected>{{ $t("directInvest_SignUp.placeholder") }}</option>
                    <option>
                      {{ $t("directInvest_SignUp.driver_licence") }}
                    </option>
                    <option>{{ $t("directInvest_SignUp.real_id") }}</option>
                    <option>{{ $t("directInvest_SignUp.passport") }}</option>
                  </select>
                  </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
                <label>{{ $t("directInvest_SignUp.address") }} *</label>
                  <input
                    id="txt_address"
                    type="text"
                    name="txt_address"
                    required
                  />
                  </b-col>
              <b-col sm="4">
                <label>{{ $t("directInvest_SignUp.city") }} *</label>
                  <input
                    id="txt_city"
                    type="text"
                    name="txt_city"
                    required
                  />
                  </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
                <label>{{ $t("directInvest_SignUp.country") }} *</label>
                <template>
                  <b-form-select id="drpdwn_country" name="drpdwn_country" v-model="selected_investor" :options='getCountries()' required>
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>{{$t("countries.250")}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </template>
                  </b-col>
                  <b-col sm>
                <label>{{ $t("directInvest_SignUp.state") }} *</label>
                  <input
                    id="txt_state"
                    type="text"
                    name="txt_state"
                    required
                  />
                  </b-col>
              <b-col sm="4">
                <label>{{ $t("directInvest_SignUp.zipCode") }} *</label>
                  <input
                    id="nr_zipCode"
                    type="number"
                    name="nr_zipCode"
                    required
                  />
                  </b-col>
            </b-row>
            <b-row>
              <b-col sm="4"><!--10 digits -->
                  <label>{{ $t("directInvest_SignUp.phone") }} *</label>
                  <input
                    id="nr_phone"
                    type="number"
                    name="nr_phone"
                    required
                  />
                  </b-col>
              <b-col sm="4">
                <label>{{ $t("directInvest_SignUp.email") }} *</label>
                  <input
                    id="email_address"
                    type="email"
                    name="email_address"
                    v-on:input="input_check_email"
                    required
                  /></b-col>
            </b-row>
            <div id="parental_guardian_info" style="display: none">
                <b-row>
              <b-col>
                  <span class="sectionTitle">
                    {{ $t("directInvest_SignUp.parental_guardian") }}
                  </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                  <span class="sectionInformation">
                    {{ $t("directInvest_SignUp.guardian_info") }}
                  </span>
              </b-col>
            </b-row>
              <b-row>
                <b-col sm>
                  <label>{{ $t("directInvest_SignUp.firstname") }} *</label>
                  <input
                    id="txt_firstname_guardian"
                    type="text"
                    name="txt_firstname_guardian"
                    v-on:input="input_check_name('txt_firstname_guardian')"
                  />
                </b-col>
                <b-col sm>
                  <label>{{ $t("directInvest_SignUp.lastname") }} *</label>
                  <input
                    id="txt_lastname_guardian"
                    type="text"
                    name="txt_lastname_guardian"
                    v-on:input="input_check_name('txt_lastname_guardian')"
                  />
                </b-col>
                <b-col sm>
                  <label>{{$t("directInvest_SignUp.government_issued_id")}}*</label>
                  <select
                    id="drpdwn_governmentID_guardian"
                    name="drpdwn_governmentID_guardian"
                  >
                    <option value="" disabled selected>{{ $t("directInvest_SignUp.placeholder") }}</option>
                    <option>
                      {{ $t("directInvest_SignUp.driver_licence") }}
                    </option>
                    <option>{{ $t("directInvest_SignUp.real_id") }}</option>
                    <option>{{ $t("directInvest_SignUp.passport") }}</option>
                  </select>
                </b-col>
              </b-row>
              <b-row>
              <b-col sm>
                <label>{{ $t("directInvest_SignUp.country") }} *</label>
                <template>
                  <b-form-select id="drpdwn_country_guardian" name="drpdwn_country_guardian" v-model="selected_guardian" :options='getCountries()' required>
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>{{$t("countries.250")}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </template>
                  </b-col>
              <b-col sm>
                <label>{{ $t("directInvest_SignUp.city") }} *</label>
                  <input
                    id="txt_city_guardian"
                    type="text"
                    name="txt_city_guardian"
                    required
                  />
                  </b-col>
                    <b-col sm>
                    <label>{{ $t("directInvest_SignUp.phone") }} *</label>
                  <input
                    id="nr_phone_guardian"
                    type="number"
                    name="nr_phone_guardian"
                    required
                  />
                  </b-col>
            </b-row>
              <b-row>
                <b-col sm="4">
                  <label>{{ $t("directInvest_SignUp.socialSecNum") }} *</label>
                  <input
                    id="inp_socialSecNum_guardian"
                    type="number"
                    name="inp_socialSecNum_guardian"
                  />
                </b-col>
                <b-col sm="4">
                  <label>{{$t("directInvest_SignUp.socialSecNum_confirmation")}}*</label>
                  <input
                    id="inp_socialSecNumConfirm_guardian"
                    type="number"
                    name="inp_socialSecNumConfirm_guardian"
                    v-on:keyup="confirm_SSN('inp_socialSecNum_guardian', 'inp_socialSecNumConfirm_guardian')"
                  />
                </b-col>
              </b-row>
            </div>
             <b-row>
              <b-col>
                <span class="sectionTitle">
                    {{ $t("directInvest_SignUp.signIn_information") }}
                  </span>
                  </b-col>
            </b-row>
            <b-row>
              <b-col sm>
                <label>{{ $t("directInvest_SignUp.username") }} *</label>
                  <input
                    id="txt_username"
                    type="text"
                    name="txt_username"
                    required
                  />
                  </b-col>
              <b-col sm>
                <label>{{ $t("directInvest_SignUp.password") }} *</label>
                  <input
                    id="txt_password"
                    type="password"
                    name="txt_password"
                    v-on:input="check_password_username"
                    @input='input_check_password'
                    required
                  />
              </b-col>
              <b-col sm>
                <label>{{$t("directInvest_SignUp.password_confirmation")}}*</label>
                  <input
                    id="txt_passwordConfirm"
                    type="password"
                    name="txt_passwordConfirm"
                    v-on:keyup="check_password"
                    required
                  />
              </b-col>
            </b-row>
        <!-- Beneficiary Information--------------------------------------------------------------------------->
            <b-row>
              <b-col>
                <span class="sectionTitle">
                    {{ $t("directInvest_SignUp.beneficiary_information") }}
                  </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="sectionInformation">
                    {{ $t("directInvest_SignUp.beneficiary_designation") }}
                  </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span>
                  {{$t("directInvest_SignUp.beneficiary_type")}}
                  </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
                <p class="radio_button">
                <input
                    class="radio_button_align"
                    type="radio"
                    id="beneficiary_inf_ind"
                    name="beneficiary_radio"
                    value="beneficiary_individual"
                    required
                  />
                  <label for="contingent_beneficiary">
                    {{ $t("directInvest_SignUp.beneficiary_individual") }}
                  </label>
                  </p>
              </b-col>
              <b-col sm="4">
                <p class="radio_button">
                <input
                    class="radio_button_align"
                    type="radio"
                    id="beneficiary_inf_trust"
                    name="beneficiary_radio"
                    value="beneficiary_trust"
                    required
                  />
                  <label for="contingent_beneficiary">
                    {{ $t("directInvest_SignUp.beneficiary_trust") }}
                  </label>
                  </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm>
                <label>{{ $t("directInvest_SignUp.firstname") }} *</label>
                  <input
                    id="txt_firstname_beneficiary_info"
                    type="text"
                    name="txt_firstname_beneficiary_info"
                    v-on:input="input_check_name('txt_firstname_beneficiary_info')"
                    required
                  />
              </b-col>
              <b-col sm>
                <label>{{ $t("directInvest_SignUp.lastname") }} *</label>
                  <input
                    id="txt_lastname_beneficiary_info"
                    type="text"
                    name="txt_lastname_beneficiary_info"
                    v-on:input="input_check_name('txt_lastname_beneficiary_info')"
                    required
                  />
              </b-col>
              <b-col sm>
                <label>{{ $t("directInvest_SignUp.suffix") }}</label>
                  <input
                    id="txt_suffix_beneficiary_info"
                    type="text"
                    name="txt_suffix_beneficiary_info"
                  />
              </b-col>
            </b-row>
            <b-row>
                <b-col sm>
                  <label>{{ $t("directInvest_SignUp.date_of_birth") }} *</label>
                  <input
                    id="date_of_birth_beneficiary_info"
                    type="date"
                    name="date_of_birth_beneficiary_info"
                    @change='reportUnderaged("date_of_birth_beneficiary_info")'
                    required
                  />
                </b-col>
                <b-col sm>
                  <!-- SSN Format 001-01-0001 -->
                  <label>{{ $t("directInvest_SignUp.socialSecNum") }} *</label>
                  <input
                    id="nr_socialSecNum_beneficiary_info"
                    type="number"
                    name="nr_socialSecNum_beneficiary_info"
                    required
                  />
                </b-col>
                <b-col sm>
                  <label>{{$t("directInvest_SignUp.socialSecNum_confirmation")}}*</label>
                  <input
                    id="nr_socialSecNumConfirm_beneficiary_info"
                    type="number"
                    name="nr_socialSecNumConfirm_beneficiary_info"
                    v-on:keyup="confirm_SSN('nr_socialSecNum_beneficiary_info','nr_socialSecNumConfirm_beneficiary_info')"
                    required
                  />
                </b-col>
            </b-row>
            <b-row>
              <b-col sm>
              <label>{{ $t("directInvest_SignUp.country") }} *</label>
              <template>
                  <b-form-select id="drpdwn_country_beneficiary_info" name="drpdwn_country_beneficiary_info" v-model="selected_beneficiary" :options='getCountries()' required>
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>{{$t("countries.250")}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </template>
                  </b-col>
              <b-col sm>
                <label>{{ $t("directInvest_SignUp.city") }} *</label>
                  <input
                    id="txt_city_beneficiary_info"
                    type="text"
                    name="txt_city_beneficiary_info"
                    required
                  />
                  </b-col>
                    <b-col sm>
                    <label>{{ $t("directInvest_SignUp.phone") }} *</label>
                  <input
                    id="nr_phone_beneficiary_info"
                    type="number"
                    name="nr_phone_beneficiary_info"
                    required
                  />
                  </b-col>
            </b-row>
            <!-- Contingent Beneficiary--------------------------------------------------------------------------->
            <b-row>
              <b-col>
                <span class="sectionTitle">
                    {{ $t("directInvest_SignUp.contingent_beneficiary") }}
                  </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span>
                  {{$t("directInvest_SignUp.beneficiary_type")}}
                  </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
                <p class="radio_button">
                  <input
                    class="radio_button_align"
                    type="radio"
                    id="contingent_beneficiary_ind"
                    name="contingent_beneficiary_radio"
                    value="beneficiary_individual"
                    required
                  />
                  <label for="contingent_beneficiary">
                    {{ $t("directInvest_SignUp.beneficiary_individual") }}
                  </label>
                </p>
              </b-col>
              <b-col sm="4">
                <p class="radio_button">
                  <input
                    class="radio_button_align"
                    type="radio"
                    id="contingent_beneficiary_trust"
                    name="contingent_beneficiary_radio"
                    value="beneficiary_trust"
                    required
                  />
                  <label for="contingent_beneficiary">
                    {{ $t("directInvest_SignUp.beneficiary_trust") }}
                  </label>
                </p>
              </b-col>
            </b-row>
            <b-row>
                <b-col sm>
                  <label>{{ $t("directInvest_SignUp.firstname") }} *</label>
                  <input
                    id="txt_firstname_contingent_benef"
                    type="text"
                    name="txt_firstname_contingent_benef"
                    v-on:input="input_check_name('txt_firstname_contingent_benef')"
                    required
                  />
                </b-col>
                <b-col sm>
                  <label>{{ $t("directInvest_SignUp.lastname") }} *</label>
                  <input
                    id="txt_lastname_contingent_benef"
                    type="text"
                    name="txt_lastname_contingent_benef"
                    v-on:input="input_check_name('txt_lastname_contingent_benef')"
                    required
                  />
                </b-col>
                <b-col sm>
                  <label>{{ $t("directInvest_SignUp.suffix") }}</label>
                  <input
                    id="txt_suffix_contingent_benef"
                    type="text"
                    name="txt_suffix_contingent_benef"
                  />
                </b-col>
            </b-row>
              <b-row>
                <b-col sm>
                  <label>{{ $t("directInvest_SignUp.date_of_birth") }} *</label>
                  <input
                    id="date_of_birth_contingent_benef"
                    type="date"
                    name="date_of_birth_contingent_benef"
                    @change='reportUnderaged("date_of_birth_contingent_benef")'
                    required
                  />
                </b-col>
                <b-col sm>
                  <!-- SSN Format 001-01-0001 -->
                  <label>{{ $t("directInvest_SignUp.socialSecNum") }} *</label>
                  <input
                    id="nr_socialSecNum_contingent_beneficiary"
                    type="number"
                    name="nr_socialSecNum_contingent_beneficiary"
                    required
                  />
                </b-col>
                <b-col sm>
                  <label>{{$t("directInvest_SignUp.socialSecNum_confirmation")}}*</label>
                  <input
                    id="nr_socialSecNumConfirm_contingent_beneficiary"
                    type="number"
                    name="nr_socialSecNumConfirm_contingent_beneficiary"
                    v-on:keyup="confirm_SSN('nr_socialSecNum_contingent_beneficiary','nr_socialSecNumConfirm_contingent_beneficiary')"
                    required
                  />
                </b-col>
            </b-row>
            <b-row>
            <b-col sm>
                <label>{{ $t("directInvest_SignUp.country") }} *</label>
                <template>
                  <b-form-select id="drpdwn_country_contingent_benef" name="drpdwn_country_contingent_benef" v-model="selected_contingent" :options='getCountries()' required>
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>{{$t("countries.250")}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </template>
                  </b-col>
                  <b-col sm>
                <label>{{ $t("directInvest_SignUp.city") }} *</label>
                  <input
                    id="txt_city_contingent_benef"
                    type="text"
                    name="txt_city_contingent_benef"
                    required
                  />
                  </b-col>
                  <b-col sm>
                    <label>{{ $t("directInvest_SignUp.phone") }} *</label>
                  <input
                    id="nr_phone_contingent_benef"
                    type="number"
                    name="nr_phone_contingent_benef"
                    required
                  />
                  </b-col>
            </b-row>
              </b-container>
              </div>
              <!-- tab for bank account information-->
              <div class="tab">
                <b-container>
               <b-row>
                 <b-col>
                   <span class="sectionTitle">
                    {{ $t("directInvest_SignUp.bank_acc") }}
                  </span>
                 </b-col>
               </b-row>
               <b-row>
                <b-col sm>
                  <label>{{ $t("directInvest_SignUp.firstname") }} *</label>
                  <input
                    id="txt_firstname_bank_acc"
                    type="text"
                    name="txt_firstname_bank_acc"
                    required
                  />
                </b-col>
                    <b-col sm>
                      <label>{{ $t("directInvest_SignUp.lastname") }} *</label>
                  <input
                    id="txt_lastname_bank_acc"
                    type="text"
                    name="txt_lastname_bank_acc"
                    required
                  />
                  </b-col>
               </b-row>
               <b-row>
                 <b-col sm>
                   <label>{{ $t("directInvest_SignUp.bank_name") }} *</label>
                  <input
                    id="txt_bank_name"
                    type="text"
                    name="txt_bank_name"
                    required
                  />
                 </b-col>
                 <b-col sm>
                   <label>{{ $t("directInvest_SignUp.bic_swift") }} *</label>
                  <input
                    id="nr_bic_swift"
                    type="text"
                    name="nr_bic_swift"
                    required
                  />
                 </b-col>
               </b-row>
               <b-row>
                 <b-col sm>
                   <label>{{ $t("directInvest_SignUp.acc_nr") }} *</label>
                  <input
                    id="nr_acc_number"
                    type="text"
                    name="nr_acc_number"
                    required
                  />
                 </b-col>
                 <b-col sm>
                   <label>{{ $t("directInvest_SignUp.acc_type") }} *</label>
                  <input
                    id="txt_acc_type"
                    type="text"
                    name="txt_acc_type"
                    required
                  />
                 </b-col>
               </b-row>
               </b-container>
              </div>
              <div class="tab">
                <b-container>
                  <b-row>
                    <b-col>
                      <span class="sectionTitle">
                    {{ $t("directInvest_SignUp.activation_tab") }}
                  </span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <span class="sectionInformation">
                    {{ $t("directInvest_SignUp.activate_account") }}
                  </span>
                    </b-col>
                  </b-row>
                <!--<p class="gridButton">
                  <button
                    type="submit"
                    class="btn_directInvest_SignUp"
                    name="submit"
                    value="Submit Now"
                    id="submit"
                    v-on:submit="signUp"
                    required
                  >
                    {{ $t("directInvest_SignUp.btn_signUp") }}
                  </button>
                </p>-->
                </b-container>
                </div>
                <b-container>
              <b-row>
                <b-col>
                  <p>
                  <button type="button" id="prevBtn" @click="nextPrev(-1)">
                    {{ $t("directInvest_SignUp.btn_previous") }}
                  </button>
                  </p>
                </b-col>
                <b-col>
                  <p>
                  <button type="button" id="nextBtn" @click="nextPrev(1)">
                    {{ $t("directInvest_SignUp.btn_next") }}
                  </button>
                  </p>
              </b-col>
              </b-row>
                </b-container>
            </form>

              <!-- Circles which indicates the steps of the form: -->
              <div style="text-align: center; margin-top: 40px">
                <span class="step"></span>
                <span class="step"></span>
                <span class="step"></span>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import dataService from "../services/dataService";

export default {
  name: "directInvest_SignUp",
  data() {
    return {
      currentTab: 0, // Current tab is set to be the first tab (0)
      selected_investor: null,
      selected_beneficiary: null,
      selected_contingent: null,
      selected_guardian: null,
    }
    },
  mounted() {
    this.showTab(this.currentTab);
  },
  methods: {
    //check if password matches with password confirmation
    check_password: function () {
      if (
        document.getElementById("txt_password").value ==
          document.getElementById("txt_passwordConfirm")
            .value &&
        document.getElementById("txt_password").value !== "" &&
        document.getElementById("txt_passwordConfirm")
          .value !== ""
      ) {
        document
          .getElementById("txt_passwordConfirm")
          .setCustomValidity("");
      } else {
        document
          .getElementById("txt_passwordConfirm")
          .setCustomValidity(
            this.$i18n.t("directInvest_SignUp.password_match")
          );
        document
          .getElementById("txt_passwordConfirm")
          .reportValidity();
      }
    },

    //check if password is not the same as username
    check_password_username: function () {
      if (
        document.getElementById("txt_password").value ==
        document.getElementById("txt_username").value
      ) {
        document
          .getElementById("txt_password")
          .setCustomValidity(
            this.$i18n.t("directInvest_SignUp.password_username")
          );
        document.getElementById("txt_password").reportValidity();
      } else {
        document
          .getElementById("txt_password")
          .setCustomValidity("");
      }
    },

    //check if name input is at least 2 characters long
    input_check_name: function (name_id) {
      var name = document.getElementById(name_id);
      if (name.value.length > 1) {
        name.setCustomValidity("");
      } else {
        name.setCustomValidity(this.$i18n.t("directInvest_SignUp.input_check"));
        name.reportValidity();
      }
    },

    //check if email input is a valid email address
    input_check_email: function () {
      if (
        /([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,})$/.test(
          document.getElementById("email_address").value
        ) == true
      ) {
        document
          .getElementById("email_address")
          .setCustomValidity("");
      } else {
        document
          .getElementById("email_address")
          .setCustomValidity(this.$i18n.t("directInvest_SignUp.email_check"));
        document.getElementById("email_address").reportValidity();
      }
    },

    //check if the password is matching with the given format
    input_check_password: function () {
      if (
        /((?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{8,})$/.test(
          document.getElementById("txt_password").value
        ) == true
      ) {
        document
          .getElementById("txt_password")
          .setCustomValidity("");
      } else {
        document
          .getElementById("txt_password")
          .setCustomValidity(this.$i18n.t("directInvest_SignUp.password_check"));
        document.getElementById("txt_password").reportValidity();
      }
    },

    //check if social security number matches with social security number confirmation
    confirm_SSN: function (ssn_id, ssnConfirmation_id) {
      var ssn = document.getElementById(ssn_id);
      var ssnConfirmation = document.getElementById(ssnConfirmation_id);
      if (
        ssn.value == ssnConfirmation.value &&
        ssn.value !== "" &&
        ssnConfirmation.value !== ""
      ) {
        ssnConfirmation.setCustomValidity("");
      } else {
        ssnConfirmation.setCustomValidity(
          this.$i18n.t("directInvest_SignUp.ssn_match")
        );
        ssnConfirmation.reportValidity();
      }
    },

    //display the specified tab of the form
    showTab: function (n) {
      // This function will display the specified tab of the form ...
      var x = document.getElementsByClassName("tab");

      //TESTING
      console.log("n showTab: " + n);
      console.log(x[n]);
      if(n < 3) {
        x[n].style.display = "block";
      }
      // ... and fix the Previous/Next buttons:
      if (n == 0) {
        document.getElementById("prevBtn").style.display = "none";
      } else {
        document.getElementById("prevBtn").style.display = "inline";
      }
      if (n == x.length - 1) {
        document.getElementById("nextBtn").innerHTML = "Submit";
      } else {
        document.getElementById("nextBtn").innerHTML = "Next";
      }
      // ... and run a function that displays the correct step indicator:
      this.fixStepIndicator(n);
    },

    //go to the next tab in the form
    nextPrev: function (n) {
      // This function will figure out which tab to display
      var x = document.getElementsByClassName("tab");
      // Exit the function if any field in the current tab is invalid:
      if (n == 1 && !this.validateForm()) return false;
      // Hide the current tab:
      x[this.currentTab].style.display = "none";
      // Increase or decrease the current tab by 1:
      this.currentTab = this.currentTab + n;
      // if you have reached the end of the form... :
      if (this.currentTab >= x.length) {
        //...the form gets submitted:
        //document.getElementById("directInvest_SignUpFormular").submit();
        this.submitFormSign();
      }
      // Otherwise, display the correct tab:
      this.showTab(this.currentTab);
    },
    
    fixStepIndicator: function (n) {
      // This function removes the "active" class of all steps...
      var i,
        x = document.getElementsByClassName("step");
      for (i = 0; i < x.length; i++) {
        x[i].className = x[i].className.replace(" active", "");
      }
      //... and adds the "active" class to the current step:
      x[n].className += " active";
    },

    //Check if all the required inputs are filled out correctly
    validateForm: function () {
    // This function deals with validation of the form fields
    var x, inputList, i, valid = true;
    x = document.getElementsByClassName("tab");
    
    inputList = x[this.currentTab].getElementsByTagName("input");
   
    //Check if user is 18
    if (this.needParentalGuardianInput()) {
      var j = 0;
      var inputListIs18 = [];
      for (i = 0; i < inputList.length; i++) {
        if(!(inputList[i].id.includes("guardian"))) {
          inputListIs18[j] = inputList[i];
          j++;
        }
      }
      inputList = inputListIs18;
      
    }
    
    
    // Loop that reports every Validity of the inputfields
    for (i = 0; i < inputList.length; i++) {
      var validity = document.getElementById(inputList[i].id).reportValidity();
    // If validity of a field is false
    if (!validity) {
      // add an "invalid" class to the field:
      // and set the current valid status to false:
      valid = false;
    
    }
  }
    // If the valid status is true, mark the step as finished and valid:
    if (valid) {
      document.getElementsByClassName("step")[this.currentTab].className += " finish";
    }
    return valid; // return the valid status
  },

    //check age of beneficiary, must be above 18
    reportUnderaged: function(age_id) {
      var age = document.getElementById(age_id);
    if (this.is18(age.value) == true)
      {
        age.setCustomValidity("");
      } else {
        age.setCustomValidity(
          this.$i18n.t("directInvest_SignUp.benef_age")
        );
        age.reportValidity();
      }
    },

    //show parental guardian input fields if underaged
    showGuardianInput: function() {
    var x = document.getElementById("parental_guardian_info");
    if (this.needParentalGuardianInput()) {
        x.style.display = "none";
        this.setRequired_none();
      } else {
        x.style.display = "block";
        this.setRequired();
      }
    },

    //set the parental guardian inputs to required
    setRequired: function() {
      document.getElementById("txt_firstname_guardian").setAttribute("required", "required");
      document.getElementById("txt_lastname_guardian").setAttribute("required", "required");
      document.getElementById("drpdwn_governmentID_guardian").setAttribute("required", "required");
      document.getElementById("inp_socialSecNum_guardian").setAttribute("required", "required");
      document.getElementById("inp_socialSecNumConfirm_guardian").setAttribute("required", "required");
    },

    //set the parental guardian inputs to none required
    setRequired_none: function() {
      document.getElementById("txt_firstname_guardian").setAttribute("required", "none");
      document.getElementById("txt_lastname_guardian").setAttribute("required", "none");
      document.getElementById("drpdwn_governmentID_guardian").setAttribute("required", "none");
      document.getElementById("inp_socialSecNum_guardian").setAttribute("required", "none");
      document.getElementById("inp_socialSecNumConfirm_guardian").setAttribute("required", "none");
    },

    //Check if parental guardian input is needed
    needParentalGuardianInput: function() {
      var date_of_birth = document.getElementById("date_of_birth").value;
      return this.is18(date_of_birth);
    },

    //check if an age input is 18 or higher
    is18: function(userAge) {
     // convert user input value into date object
     var birthDate = new Date(userAge);
      // get difference from current date;
      var age_diff = Date.now() - birthDate.getTime();
      var ageDate = new Date(age_diff);
      var age = Math.abs(ageDate.getUTCFullYear() - 1970);
      if(age > 17) {
        return true;
      } else {
        return false;
      }
    },

    //get the countries from English.json for country dropdown
    getCountries: function() {
      var countries = [];
      for (let index = 0; index <=249 ; index++) {
        countries[index] = this.$i18n.t("countries." + index);
      }
      return countries;
    },

    submitFormSign: function() {

      //Personal Information
      var inputaccOptions = document.getElementById("drpdwn_account_type");
      var accountOptions = inputaccOptions.options[inputaccOptions.selectedIndex].text;
      var firstName = document.getElementById("txt_firstname").value;
      var lastName = document.getElementById("txt_lastname").value;
      var suffix = document.getElementById("txt_suffix").value;
      var dateOfBirth = document.getElementById("date_of_birth").value;
      var socialSecurityNumber = document.getElementById("inp_socialSecNum").value;
      var inputIssuedId = document.getElementById("drpdwn_governmentID");
      var id_type = inputIssuedId.options[inputIssuedId.selectedIndex].text;
      var addressText = document.getElementById("txt_address").value;
      var cityText = document.getElementById("txt_city").value;
      var inputCountry = document.getElementById("drpdwn_country");
      var countryText = inputCountry.options[inputCountry.selectedIndex].text;
      var stateText = document.getElementById("txt_state").value;
      var zipCode = document.getElementById("nr_zipCode").value;
      var phoneNr = document.getElementById("nr_phone").value;
      var mail = document.getElementById("email_address").value;

      //Parental Guardian
      var firstNameGuardian = document.getElementById("txt_firstname_guardian").value;
      var lastNameGuardian = document.getElementById("txt_lastname_guardian").value;
      var inputCountryGuardian = document.getElementById("drpdwn_country_guardian");
      var countryTextGuardian = inputCountryGuardian.options[inputCountryGuardian.selectedIndex].text;
      var cityTextGuardian = document.getElementById("txt_city_guardian").value;
      var phoneNrGuardian = document.getElementById("nr_phone_guardian").value;
      var inputIssuedIdGuardian = document.getElementById("drpdwn_governmentID_guardian");
      var parentalGuaridanId = inputIssuedIdGuardian.options[inputIssuedIdGuardian.selectedIndex].text;
      var socialSecurityNumberGuardian = document.getElementById("inp_socialSecNum_guardian").value;


      //Sign in information

      var userName = document.getElementById("txt_username").value;
      var password = document.getElementById("txt_password").value;

     
      //Beneficiary information
        //Radio Button note sure if this works
    

      var radiosbenef = document.getElementsByName('beneficiary_radio');

      for (var i = 0, length = radiosbenef.length; i < length; i++) {
        if (radiosbenef[i].checked) {
          // do whatever you want with the checked radio
          var beneficiary_type= (radiosbenef[i].value);
          // only one radio can be logically checked, don't check the rest
          break;
        }
      }



      var firstNameBenef = document.getElementById("txt_firstname_beneficiary_info").value;
      var lastNameBenef = document.getElementById("txt_lastname_beneficiary_info").value;
      var suffixBenef = document.getElementById("txt_suffix_beneficiary_info").value;
      var dateOfBirthBenef = document.getElementById("date_of_birth_beneficiary_info").value;
      var socialSecurityNumberBenef = document.getElementById("nr_socialSecNum_beneficiary_info").value;
      var inputCountryBenef = document.getElementById("drpdwn_country_contingent_benef");
      var countryTextBenef = inputCountryBenef.options[inputCountryBenef.selectedIndex].text;
      var cityTextBenef = document.getElementById("txt_city_beneficiary_info").value;
      var pohneNumberBenef = document.getElementById("nr_phone_beneficiary_info").value;

      //Contingent beneficiary
       
      var radios = document.getElementsByName('contingent_beneficiary_radio');

      for (var a = 0, lengthcontingent = radios.length; a < lengthcontingent; a++) {
        if (radios[a].checked) {
          // do whatever you want with the checked radio
          var contingent_type= (radios[a].value);
          // only one radio can be logically checked, don't check the rest
          break;
        }
      }

      var firstNameContin = document.getElementById("txt_firstname_contingent_benef").value;
      var lastNameContin = document.getElementById("txt_lastname_contingent_benef").value;
      var suffixContin = document.getElementById("txt_suffix_contingent_benef").value;
      var dateOfBirthContin = document.getElementById("date_of_birth_contingent_benef").value;
      var socialSecurityContin = document.getElementById("nr_socialSecNum_contingent_beneficiary").value;
      var inputCountryContin = document.getElementById("drpdwn_country_contingent_benef");
      var countryTextBenefContin = inputCountryContin.options[inputCountryContin.selectedIndex].text;
      var cityTextContin = document.getElementById("txt_city_contingent_benef").value;
      var pohneNumberContin = document.getElementById("nr_phone_contingent_benef").value;

      //Bank account Information
      var firstNameBankAcc = document.getElementById("txt_firstname_bank_acc").value;
      var lastNameBankAcc = document.getElementById("txt_lastname_bank_acc").value;
      var nameOfTheBank = document.getElementById("txt_bank_name").value;
      var swift = document.getElementById("nr_bic_swift").value;
      var accountNumber = document.getElementById("nr_acc_number").value;
      var account_type = document.getElementById("txt_acc_type").value;


      var user = {mail: mail, first_name: firstName, last_name: lastName, city: cityText, country: countryText, state: stateText, address: addressText, phone_nr: phoneNr, suffix: suffix, birth: dateOfBirth, zip: zipCode, ssn: socialSecurityNumber};
      var guardUser = {first_name: firstNameGuardian, last_name: lastNameGuardian, city: cityTextGuardian, country: countryTextGuardian, phone_nr: phoneNrGuardian, ssn: socialSecurityNumberGuardian};
      var benefUser = {first_name: firstNameBenef, last_name: lastNameBenef, city: cityTextBenef, country: countryTextBenef, phone_nr: pohneNumberBenef, suffix: suffixBenef, birth: dateOfBirthBenef, ssn: socialSecurityNumberBenef};
      var continUser = {first_name: firstNameContin, last_name: lastNameContin, city: cityTextContin, country: countryTextBenefContin, phone_nr: pohneNumberContin, suffix: suffixContin, birth: dateOfBirthContin, ssn: socialSecurityContin};
      var bankAccount = {first_name: firstNameBankAcc, last_name: lastNameBankAcc, bankname: nameOfTheBank, swift: swift, account_nr: accountNumber, account_type: account_type};
      //var login = {username: userName, password: password};

      var directInvest = {
          user: user,
          id_type: id_type,
          account_type: accountOptions,
          parentalGuardian: guardUser,
          parentalGuaridan_ID_type: parentalGuaridanId,
          beneficiary_type: beneficiary_type,
          beneficiant: benefUser,
          contingent_type: contingent_type,
          contingent: continUser,
          bankAccount: bankAccount,
      }

      //dataService.createSignUp(user, guardUser, benefUser, continUser, bankAccount, accountOptions, login, beneficiary_type, contingent_type, parentalGuaridanId, id_type);
      dataService.createLogin( userName, password, "Investor", directInvest);


    }

  }
}
</script>

<style scoped>
/*CSS for form tabs*/
/* Hide all steps by default: */

.tab {
  display: none;
}

/* Make circles that indicate the steps of the form: */
.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #777776;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

/* Mark the active step: */
.step.active {
  opacity: 1;
}

/* Mark the steps that are finished and valid: */
.step.finish {
  background-color: #f4892f;
}

/*-----------------------------------------------------*/
.col {
  margin-top: 15px;
}

.radio_button {
  display: inline-flex;
  align-items: center;
}

.radio_button_align {
  width: auto !important;
  margin-right: 10px;
}

.sectionTitle {
  font-size: 25px;
  margin-top: 20px;
}
.sectionInformation {
  font-size: 15px;
  margin-top: 20px;
}
.pdf-btn {
  margin: 0px 10px;
  padding: 14px 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: blue;
  text-decoration: none;
  color: white;
}
.upload-btn {
  padding: 7px 8px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: orange;
  text-decoration: none;
  color: white;
}

/* CSS for contact form */
.container_directInvest_SignUp {
  width: 100%;
  background-color: #f4892f;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}

.container_mid {
  width: 100%;
}
h2 {
  margin: 0;
}
a {
  color: black;
}
.mail_crypt {
  unicode-bidi: bidi-override;
  direction: rtl;
}

/* VolunteerForm */

* {
  box-sizing: border-box;
}

body {
  background: #f9c75c;
  color: #f4892f;
  line-height: 1.6;
  padding: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
}

.brand span {
  color: #fff;
}

.wrapper {
  box-shadow: 0 0 20px 0 rgba(243, 142, 19, 0.7);
}

.wrapper > * {
  padding: 1em;
}

.directInvest_SignUpForm {
  background: #f9c75c;
}

.container_mid form label {
  display: block;
}

.container_mid form p {
  margin: 0;
}

.container_mid button{
margin-top: 2em;
}
.container_mid button,
.container_mid form button,
.container_mid form input,
.container_mid form textarea,
.container_mid form select,
.container_mid form option {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #faa535;
}

.container_mid button,
.container_mid form button {
  background: #f4892f;
  float: left;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
}


.container_mid label {
  margin-top: 0.5em;
}

.container_mid form button:hover,
.container_mid form button:focus {
  background: #b33d06;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
  cursor: pointer;
}

.alert {
  text-align: center;
  padding: 10px;
  background: #79c879;
  color: #fff;
  margin-bottom: 10px;
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
  margin: 0;
}

/* LARGE SCREENS */
@media (min-width: 700px) {
  .wrapper {
    display: grid;
  }

  .wrapper > * {
    padding: 2em;
  }

  .brand {
    text-align: left;
  }
}
</style>