<template>
  <div id="directInvest_Opportunities_Details">
    <button class="collapsible" @click="openCollapsible">
      {{title}}
    </button>
    <div v-if="opened">
      <h5>Funds raised:</h5>
      <b-progress id="fundraiser-bar" :value="fundraised" :max="maxraised" show-progress></b-progress>
      <b-container>
        <b-row>
          <b-col sm>
            <p>{{description}}</p>
          </b-col>
          <b-col sm>
            <div class="pdf-box">
               <ul>
                  <li class="pdf-docu" v-for="item in pdf_details" v-bind:key="item.id">
                      <directInvest_Opportunities_Details_Pdf :id=item.id :href=item.href :download=item.download :filename=item.filename />
                 </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div>
        <b-table striped hover :items="conditions"></b-table>
      </div>
      <router-link v-if='investmentUser.Username!="No User"' :to="'/directinvest/' + this.id">
            <b-button id="invest_button" variant="primary">Invest Now</b-button>
      </router-link>
      <router-link v-else :to="'/directinvest/login'">
            <b-button id="invest_button" variant="primary">Login</b-button>
      </router-link>
      
    </div>
  </div>
</template>

<script>
import directInvest_Opportunities_Details_Pdf from './directInvest_Opportunities_Details_Pdf.vue';
export default {
  components: { directInvest_Opportunities_Details_Pdf },
  name: 'directInvest_Opportunities_Details',
  mounted() {
    this.opened = false;
  },
  data() {
      return {
        conditions: [
          { id: this.id, '% funded': this.fundraised, 'interest-rate': this.interestrate, 'Term (years)': this.term, 'Special Benefits': this.specialBenefit }
        ],
        pdf_details:[
          {id: 1, href:"ethical_Guidelines_and_User_Code_of_Conduct.pdf",download:"ethical_Guidelines_and_User_Code_of_Conduct.pdf",filename:"Guidelines"},
          {id: 2, href:"pledge_and_Security.pdf",download:"pledge_and_Security.pdf",filename:"Pledge and Security"},
          {id: 3, href:"privacy_Policy.pdf",download:"privacy_Policy.pdf",filename:"Privacy Policy"},
          {id: 4, href:"supplemental_Indenture-2.pdf",download:"supplemental_Indenture-2.pdf",filename:"Supplemental Indenture"},
          {id: 5, href:"prospectus.pdf",download:"prospectus.pdf",filename:"Prospectus"}
      ]
      }
  },
  props: {
    opened: Boolean,
    title: String,
    fundraised: Number,
    maxraised: Number,
    description: String,
    id: Number,
    interestrate: String,
    term: String,
    specialBenefit: String,
    investmentUser: {
      type: Object,
      default: function () {
        return {Username: "No User"};
      }
      },

  },
  methods: {
    openCollapsible: function () {
      if (this.opened) {
        this.opened = false;
      } else {
        this.opened = true;
      }
    }
  }
}

</script>

<style scoped>

  #fundraiser-bar {
    margin-bottom: 10px;
  }
  #invest_button {
    float:right;
  }
  ul {
    list-style-type: none;
    padding-left: 20px;  
} 
</style>