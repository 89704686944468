<template>
  <div>
    <div id="event_column">
      <div
        class="registeredEvent"
        v-for="(event, index) in eventList"
        :key="index"
      >
        <!-- img -->
        <img
          v-if="event.image_data"
          :src="'data:image/jpeg;base64,' + event.image_data"
          alt="image"
          height="200px"
          width="250px"
          style="
            position: absolute;
            top: 0;
            right: 0;
            margin-top: 2px;
            margin-right: 5px;
          "
        /><br />
        <!-- title -->
        <h2>{{ event.Title }}</h2>
        <!-- subtitle -->
        <h4 v-if="event.Subtitle">{{ event.Subtitle }}</h4>
        <!-- region -->
        <span v-if="event.Region">Region: {{ event.Region }}<br /></span>
        <!-- class -->
        <span v-if="event.Class != '[]'"
          >Related class:
          <span v-for="(item, index) in JSON.parse(event.Class)" :key="index"
            >{{ item }}
            <span v-if="index < JSON.parse(event.Class).length - 1">,</span>
          </span>
          <br />
        </span>
        <!-- industry -->
        <span v-if="event.Industry != '[]'"
          >Related industry:
          <span v-for="(item, index) in JSON.parse(event.Industry)" :key="index"
            >{{ item }}
            <span v-if="index < JSON.parse(event.Industry).length - 1">,</span>
          </span>
          <br />
        </span>
        <!-- age range -->
        <span v-if="event.AllAge">For all ages<br /></span>
        <span v-else-if="event.AgeStart == 0 && event.AgeEnd == 0"></span>
        <span v-else
          >Age range: {{ event.AgeStart }} to {{ event.AgeEnd }} years old<br
        /></span>
        <!-- date range -->
        <span v-if="event.DateStart != 'null'"
          >Date: {{ formatDate(event.DateStart) }} to
          {{ formatDate(event.DateEnd) }}<br
        /></span>
        <!-- time range -->
        <span v-if="event.AllDay"> For all day<br /></span>
        <span v-else-if="event.TimeStart == '' || event.TimeEnd == ''"></span>
        <span v-else>
          Time range: {{ event.TimeStart }} to {{ event.TimeEnd }}<br />
        </span>
        <!-- timezone -->
        <span v-if="event.TimeZone">Timezone: {{ event.TimeZone }}<br /></span>
        <!-- number of expected people coming for event -->
        <span v-if="event.NumExpect != 0"
          >Number of People Expected: {{ event.NumExpect }}<br
        /></span>
        <!-- cost -->
        <span v-if="event.NoCost">Free event</span>
        <span v-else-if="event.Cost == 0"></span>
        <span v-else-if="event.Currency == 'USD'"
          >Cost: {{ event.Currency }} ${{ event.Cost }} <br
        /></span>
        <span v-else> Cost:{{ event.Cost }}{{ event.Currency }} </span>
        <!-- meeting type -->
        <span v-if="event.MeetingType"
          >Meeting type: {{ event.MeetingType }}<br
        /></span>
        <!-- country -->
        <span v-if="event.Country">Country: {{ event.Country }}<br /></span>
        <!-- city -->
        <span v-if="event.City">City: {{ event.City }}<br /></span>
        <!-- sponsor -->
        <span v-if="event.Sponsor == true">
          This event is sponsored <br
        /></span>
        <span v-else> This event is NOT sponsored <br /> </span>
        <!-- description -->
        Details:
        <span
          style="word-wrap: break-word"
          v-if="event.Description"
          v-html="event.Description"
        ></span>
        <!-- item list -->
        <div v-if="event.ItemList != '[]'">
          <span>Item list:</span>
          <ul>
            <li
              v-for="(item, index) in JSON.parse(event.ItemList)"
              :key="index"
            >
              {{ item.amount }} {{ item.unit }} {{ item.name }}
            </li>
          </ul>
          <br />
        </div>
        <!-- download file -->
        <button
          class="download-btn"
          v-if="event.doc_data"
          @click="downloadFile(event.doc_data, event.DocFormat)"
        >
          Download File
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "../axios";

export default {
  data() {
    return {
      eventList: [],
    };
  },
  mounted() {
    this.getEventByID();
  },
  computed: {
    eventID() {
      return this.$store.state.eventID;
    },
  },
  methods: {
    getEventByID() {
      const eventIDs = this.eventID;
      //console.log(eventID);
      for (var eventID of eventIDs) {
        axios
          .post("api/getEventByID", { eventID: eventID })
          .then((response) => {
            //console.log(response.data);
            this.eventList.push(response.data[0]);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // download doc file
    downloadFile(base64Data, fileExtension) {
      const mimeTypeMap = {
        ".docx":
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ".pdf": "application/pdf",
        ".doc": "application/msword",
        ".xlsx":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ".xls": "application/vnd.ms-excel",
        ".pptx":
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        ".ppt": "application/vnd.ms-powerpoint",
        ".txt": "text/plain",
        ".csv": "text/csv",
        ".html": "text/html",
        ".htm": "text/html",
        ".js": "text/javascript",
        ".json": "application/json",
        ".xml": "application/xml",
        ".bmp": "image/bmp",
        ".svg": "image/svg+xml",
        ".mp3": "audio/mpeg",
        ".wav": "audio/wav",
        ".mp4": "video/mp4",
        ".mov": "video/quicktime",
        ".avi": "video/x-msvideo",
        // Add more MIME types as needed
      };
      const mimeType =
        mimeTypeMap[fileExtension.toLowerCase()] || "application/octet-stream";
      const blob = this.base64ToBlob(base64Data, mimeType);
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "example" + mimeType;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    base64ToBlob(base64, mimeType) {
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: mimeType });
    },
    formatDate(dateString) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const date = new Date(dateString);
      const monthName = monthNames[date.getMonth()];
      return `${monthName}/${date.getDate()}/${date.getFullYear()}`;
    },
  },
};
</script>
<style>
.registeredEvent {
  position: relative;
  left: -10px;
  top: auto;
  width: 100%;
  height: auto;
  min-height: 210px;
  border: 3px solid orange;
  background-color: orange;
  border-radius: 2px;
  margin: 10px;
}
</style>
