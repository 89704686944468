<template>
    <b-container>
      <div id="donate">
       <div id="smart-button-container">
          <b-row>
            <b-col>
               <div style="text-align: center"><label for="description"> {{ $t('donate.comment') }} </label></div>
               </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div style="text-align:center">
              <input type="text" name="descriptionInput" id="description" maxlength="127" value="">
              <p id="descriptionError">{{ $t('donate.description') }}</p>
              </div>
             </b-col>
         </b-row>
      <b-row>
        <b-col>
          <div style="text-align: center"><label for="amount">{{ $t('donate.amount') }}</label><input class="donate" name="amountInput" type="number" id="amount" value="" ><span> USD</span></div>
          <p id="priceLabelError" >{{ $t('donate.price') }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div id="invoiceidDiv" style="text-align: center; display: none;"><label for="invoiceid">{{ $t('donate.customInvoice') }} </label><input name="invoiceid" maxlength="127" type="text" id="invoiceid" value="" ></div>
          <p id="invoiceidError" >{{ $t('donate.enterInvoice') }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div style="text-align: center; margin-top: 0.625rem;" id="paypal-button-container"></div>
        </b-col>
      </b-row>
  </div>
</div>

  </b-container>
</template>


<script>

export default {
  
  mounted:function() {
    this.initiPayPalButton();
  },
    
  methods: {
    initiPayPalButton: function() {
        var description = document.querySelector('#smart-button-container #description');
        var amount = document.querySelector('#smart-button-container #amount');
        var descriptionError = document.querySelector('#smart-button-container #descriptionError');
        var priceError = document.querySelector('#smart-button-container #priceLabelError');
        var invoiceid = document.querySelector('#smart-button-container #invoiceid');
        var invoiceidError = document.querySelector('#smart-button-container #invoiceidError');
        var invoiceidDiv = document.querySelector('#smart-button-container #invoiceidDiv');
        var elArr = [description, amount];
        if (invoiceidDiv.firstChild.innerHTML.length > 1) {
          invoiceidDiv.style.display = "block";
        }
        var purchase_units = [];
        purchase_units[0] = {};
        purchase_units[0].amount = {};
        function validate(event) {
          return event.value.length > 0;
        }
        /* eslint-disable */
        paypal.Buttons({
          style: {
            color: 'gold',
            shape: 'pill',
            label: 'paypal',
            layout: 'horizontal',
          },
        /* eslint-enable */
        
          onInit: function (data, actions) {
            actions.disable();
            if(invoiceidDiv.style.display === "block") {
              elArr.push(invoiceid);
            }
            elArr.forEach(function (item) {
              item.addEventListener('keyup', function () {
                var result = elArr.every(validate);
                if (result) {
                  actions.enable();
                } else {
                  actions.disable();
                }
              });
            });
          },
          onClick: function () {
            if (description.value.length < 1) {
              descriptionError.style.visibility = "visible";
            } else {
              descriptionError.style.visibility = "hidden";
            }
            if (amount.value.length < 1) {
              priceError.style.visibility = "visible";
            } else {
              priceError.style.visibility = "hidden";
            }
            if (invoiceid.value.length < 1 && invoiceidDiv.style.display === "block") {
              invoiceidError.style.visibility = "visible";
            } else {
              invoiceidError.style.visibility = "hidden";
            }
            purchase_units[0].description = description.value;
            purchase_units[0].amount.value = amount.value;
            if(invoiceid.value !== '') {
              purchase_units[0].invoice_id = invoiceid.value;
            }
          },
          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: purchase_units,
            });
          },
          onApprove: function (data, actions) {
            return actions.order.capture().then(function () {
          
              
              // Show a success message within this page, e.g.
              const element = document.getElementById('paypal-button-container');
              element.innerHTML = '';
              element.innerHTML = '<h3>Thank you for your payment!</h3>';
              // Or go to another URL:  actions.redirect('thank_you.html');
            });
          },
          
        }).render('#paypal-button-container');
  }
  }
}


</script>

<style scoped>



p{
  visibility: hidden; 
  text-align:center;
  color: red;
}

.donate{
  width: 64px;
}

label{
  margin:10px
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
  margin: 0;
}


</style>
  
