<template>
  <div id="directInvest_Opportunities">
    <h1>Browse our opportunities</h1>
    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
    <ul id="opportunityWrapper">
      <li v-for="item in opportunities" v-bind:key="item.id">
        <directInvestment_Opportunities_Details :investmentUser=investmentUser :id=item.id :description=item.description :maxraised=item.maxRaised :fundraised=item.fundraised :interestrate=item.interestrate :specialBenefit=item.specialBenefit :term=item.term :title=item.title />
      </li>
    </ul>
  </div>
</template>

<script>
/*eslint-disable*/
import dataService from "../services/dataService";
import directInvestment_Opportunities_Details from './directInvest_Opportunities_Details.vue';
export default {
  components: { directInvestment_Opportunities_Details },
  name: 'directInvest_Opportunities',
  props: {
        isloaded: false,
        //opportunities: Array,
        investmentUser: {
        type: Object,
        default: function () {
          return {Username: "No User"};
        }
          },
  },
  data() {
    return { opportunities: Array }
  },
  mounted() {
    this.getOpportunities();
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
        }
      });
    }
  },
  methods: {
      getOpportunities: function () {
      var opportunities = [];
      dataService.getInvestment()
      .then(res => {
        for (let index = 0; index <=12 ; index++) {
          //alert(opportunityFromdb.max_raised);
          var fundraised = 0;
          var maxRaised = 100000;
          if (typeof res.data[index] === 'undefined') {
            fundraised = 0;
          } else {
              fundraised = parseFloat(res.data[index].Fundraised);
              if (typeof res.data[index].max_raised !== 'undefined') {
                maxRaised = parseFloat(res.data[index].max_raised);
              }
          }
          
          opportunities[index] = { id: (index+1), maxRaised:maxRaised, fundraised: fundraised, interestrate: this.$i18n.t("directInvest_Opportunities.rate_" + (index+1)), specialBenefit: '', term: this.$i18n.t("directInvest_Opportunities.term_" + (index+1)), title: this.$i18n.t("directInvest_Opportunities.title_" + (index+1)), description: this.$i18n.t("directInvest_Opportunities.text_" + (index+1)) };
        }
        this.opportunities = opportunities;
      })
      
    }
},
}

/*eslint-enable*/
</script>

<style scoped>
ul {
    list-style-type: none;
}
</style>