// const axios = require('axios');
import instance from "../axios"


class DataService {
 /* eslint-disable */
  //This is the current newUser Method for Login of Neuroleap
  newUser(userData) {

    const data = {
      userName: userData.username,
      email: userData.email,
      firstName: userData.firstname,
      middleInitial: userData.middleinitial,
      lastName: userData.lastname,
      password: userData.password
    }

    instance.post('api/create/login', data)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });

  }

  verifyUser(userData) {
    const data = {
      username: userData.username,
      password: userData.password
    }
    
    return new Promise((resolve, reject) => {
      try {
        const resp = instance.post('api/verifylogin', data);
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }

  //Testing the fundraising API
  getUsers() {
    instance({
      method: 'GET',
      url: 'yourURL'
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  //Testing the fundraising API
  getVolunteer() {
    instance({
      method: 'GET',
      url: 'api/volunteer'
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  getInvestment() {
    return new Promise((resolve, reject) => {
      try {
        const resp = instance.get('api/investments');
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }

  verifyLogin (username, password) {
    const data = {
      username: username,
      password: password,
    }
    return new Promise((resolve, reject) => {
      try {
        const resp = instance.post('api/verifylogin', data);
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }












  
  //Testing the fundraising API
  getEquity() {
    instance({
      method: 'GET',
      url: 'api/equity'
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  getCollaborator() {
    instance({
      method: 'GET',
      url: 'api/collaborator'
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
  }
  


  //Create User
  createUser(user) {
    instance({
      method: 'post',
      url: 'yourURL',
      data: {
        mail: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        suffix: "",
        city: user.city,
        country: user.country,
        phone_nr: "",
        state: user.state,
        address: user.address,
        dateOfBirth: user.dateOfBirth,
        zipCode: user.zipCode
      }
    })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  }

  //Create User
  createEquity(user, comment) {
    instance({
      method: 'POST',
      url: 'api/create/equity',
      data: {
        user: user,
        comment: comment
      }
    })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  }
  

    //Create Volunteer
    createVolunteer(user, why, how, base64pdf) {
      instance({
        method: 'POST',
        url: 'api/create/volunteer',
        data: {
          user: user,
          why: why,
          how: how,
          base64pdf: base64pdf
        }
      })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    }

    //Create Customer Collaborator
    createCustomerCollaborator(user, collaborator, role, cust_collab, comment) {
      instance({
        method: 'POST',
        url: 'api/collaborator',
        data: {
          referer: user,
          collaborator: collaborator,
          role: role,
          cust_collab: cust_collab,
          comment: comment
        }
      })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    }


    //Create SignUp
    createSignUp (user, guardUser, benefUser, continUser, bankAccount, login, beneficiary_type, contingent_type, accountOptions, parentalGuaridanId, id_type) {
      instance({
        method: 'post',
        url: 'api/create/directInvest',
          data: {
          user: user,
          id_type: id_type,
          account_type: accountOptions,
          login: login,
          parentalGuardian: guardUser,
          parentalGuaridan_ID_type: parentalGuaridanId,
          beneficiary_type: beneficiary_type,
          beneficiant: benefUser,
          contingent_type: contingent_type,
          contingent: continUser,
          bankAccount: bankAccount,
        }
      })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    }

    createLogin (directInvest, username, password) {
      instance({
        method: 'post',
        url: 'api/create/login',
          data: {
          username: username,
          password: password,
          directInvest: directInvest,
        }
      })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    }

    createInvestment (user, amount, opportunity) {
      instance({
        method: 'post',
        url: 'api/create/investment',
          data: {
            Amount: amount,
            Login: user,
            InvestmentOpportunity: opportunity,
        }
      })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    }

    verifyLogin (username, password) {
      const data = {
        username: username,
        password: password,
      }
      return new Promise((resolve, reject) => {
        try {
          const resp = instance.post('api/verifylogin', data);
          resolve(resp);
        } catch (err) {
          reject(err);
        }
      });
    }

    verifyLoginInvestmentPortal (username, password) {
      const data = {
        username: username,
        password: password,
      }
      return new Promise((resolve, reject) => {
        try {
          const resp = instance.post('api/verifylogininvestmentportal', data);
          resolve(resp);
        } catch (err) {
          reject(err);
        }
      });
    }
  /* eslint-enable */

}

export default new DataService;
