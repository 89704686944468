<template>
  <div id="directInvest_Invest">
    <h1>
      {{getTitle()}}
    </h1>
    <p>
      {{getText()}}
    </p>
    <div id="invest_form">
    <form
      id="directInvest_Invest"
      @submit.prevent="submitFormInvest"
      >
      <p>
        <label>
          {{$t("directInvest_Invest.dollar_input")}}
        </label>
        <input
          id="invest_amount"
          type="number"
          name="invest_amount"
          v-model="amount">
      </p>
      <p>
        <button
        type="submit"
        class="btn_invest"
        name="submit"
        value="Submit Now"
        >
        {{$t("directInvest_Invest.btn_submit")}}
        </button>
      </p>
    </form>
    </div>
    <div>
    <router-link to="/directinvest/opportunities">
            <a>Back to Opportunities</a>
      </router-link>
    </div>
    <table class="table">
      <thead>
          <tr>
              <th>Quarter</th>
              <th>Balance</th>
              <th>Interest</th>
              <th>Principal</th>
              <th>Payment</th>
          </tr>
      </thead>
      <tbody>
        <tr v-for="row in getData(amount)" v-bind:key="row.id">
              <td>{{row.id}}</td>
              <td>{{row.balance}}</td>
              <td>{{row.interest}}</td>
              <td>{{row.principal}}</td>
              <td>{{row.payment}}</td>
          </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import dataService from "../services/dataService";
export default {
  name: 'directInvest_Invest',

  mounted() {
    //set the default amount to 500
    this.amount = 500;
  },
  
  computed: {

    //Use the default amount of 500 if input field is empty
    calculateAmount: function() {
      if(this.amount < 500) {
        return 500;
      } else {
        return this.amount;
      }
    },


  },
  
  methods: {
    //get the title of the opportunity
    getTitle: function () {
    return this.$i18n.t("directInvest_Opportunities.title_" + this.$route.params.id);
    },
    //get the text of the opportunity
    getText: function () {
    return this.$i18n.t("directInvest_Opportunities.text_" + this.$route.params.id);
    },

    //get the terms of the opportunity and multiply by 4 - because of quarterly payment
    getQuarters: function() {
      var terms = parseInt(this.$i18n.t("directInvest_Opportunities.term_" + this.$route.params.id));
      var quarters = terms * 4;
      return quarters;
    },

    //get the interest rate of the opportunity and divide by 100 to use it for calculation
    getInterestrate: function() {
      var int_rate = parseFloat(this.$i18n.t("directInvest_Opportunities.rate_" + this.$route.params.id));
      var rate = int_rate / 100;
      return rate;
    },

    //get the balance, calculate interest, calculate principal, calculate payment
    getData(amount) {
      amount = parseFloat(amount);
      var row = [];
      var term = this.getQuarters();
      var interest_rate = this.getInterestrate();
      for (let index = 1; index <= term; index++) {
        if(index == 1) {
          row[index - 1] = {id: index, balance: amount, interest: ((amount*(interest_rate/4))).toFixed(2),  principal: (((amount+(amount*interest_rate))/term)-(amount*(interest_rate/4))).toFixed(2), payment: ((parseInt(this.amount) + parseInt(this.amount * this.getInterestrate())) / this.getQuarters()).toFixed(2)}
        } else {
          row[index - 1] = {id: index, balance: ((row[index - 2].balance) - (row[index - 2].principal)).toFixed(2), interest: (((row[index - 2].balance) - (row[index - 2].principal))*(interest_rate/4)).toFixed(2), principal: (((amount+(amount*interest_rate))/term)-(((row[index - 2].balance) - (row[index - 2].principal))*(interest_rate/4))).toFixed(2), payment: ((parseFloat(this.amount) + parseFloat(this.amount * this.getInterestrate())) / this.getQuarters()).toFixed(2)}
        }
        
      }
      return row;
    },

    submitFormInvest: function() {
      var amount = this.amount;
      var user = this.userInvesting;
      var opportunity = {title: this.getTitle(), fundraised: this.amount};
      dataService.createInvestment(user, amount, opportunity);

      alert("Submit successful!");
    },

  },
  
  props: {
    id: Number,
    title: String,
    invest_text: String,
    amount: Number,
    payment: Number,
    principal: Number,
    interest: Number,
    payment_total: Number,
    interest_total: Number,
    balance_rest: Number,
    principal_total: Number,
    userInvesting: {
      type: Object,
      default: function () {
        return {Username: "No User"};
      }
      },
  }
}
</script>


<style scoped>
.btn_invest{
  background: #f4892f;
  float: left;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
}

.btn_invest:hover,
.btn_Invest:focus {
  background: #b33d06;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
  cursor: pointer;
}

form input {
  width: 30%;
  padding: 1em;
  height: 2em;
}

form button {
  width: 40%;
  padding: 1em;
  border: 1px solid #faa535;
  align-items: center;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
</style>