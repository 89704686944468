<template>
  <div id="directInvest_HowItWorks">
    <h1>{{ $t('directInvest.heading') }}</h1>
    <div class="div-chapter">
      <!-- How Direct Investing Works-->
      <h2>{{ $t('directInvest.howItWorksTitle') }}</h2>
      <p>{{ $t('directInvest.howItWorksText1') }}</p>
      <p>{{ $t('directInvest.howItWorksText2') }}</p>
    </div>
    <div class="div-chapter">
      <!-- Concepts of Direct Investing-->
      <h2>{{ $t('directInvest.ConceptsTitle') }}</h2>
      <div><img src="../img/concepts.png" alt="concepts"></div>
    </div>
    <div class="div-chapter">
      <!-- Benefits of Direct Investing in NeuroLeap-->
      <h2>{{ $t('directInvest.BenefitsTitle') }}</h2>
      <div class="column-left">
        <h3>{{ $t('directInvest.fixedInterestTitle') }}</h3>
        <p>{{ $t('directInvest.fixedInterestText') }}</p>
      </div>
      <div class="column-right">
        <h3>{{ $t('directInvest.simpleTitle') }}</h3>
        <p>{{ $t('directInvest.simpleText') }}</p>
      </div>
      <div>
        <h3>{{ $t('directInvest.smallDenominationsTitle') }}</h3>
        <p>{{ $t('directInvest.smallDenominationsText') }}</p>
      </div>
      <div>
        <router-link to="/directinvest/opportunities">
          <button class="browse-btn">
            {{ $t('directInvest.browseInvestmentsButton') }}
          </button>
        </router-link>
      </div>
    </div>
    <div class="div-chapter">
      <!-- How to Invest in NeuroLeap-->
      <h2>{{ $t('directInvest.howToInvestTitle') }}</h2>
      <b-container class="bv-example-row bordered">
        <b-row>
          <b-col>
            <h4 class="bold">Step 1</h4>
            <p>The first thing to do is Registering.</p>
          </b-col>
          <b-col>
            <h4 class="bold">Step 2</h4>
            <p>Sign in and transfer funds to your NeuroLeap Account.</p>
          </b-col>
          <b-col>
            <h4 class="bold">Step 3</h4>
            <p>Browse investment opportunities and invest in opportunities that work for you.</p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="div-chapter">
      <h2>{{ $t('directInvest.opportunitiesTitle') }}</h2>
      <p>*Processing Time: 5 days</p>
      <div>
        <b-table striped hover :items="getOpportunities()"></b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'directInvest_HowItWorks',
  methods: {
    getOpportunities: function () {
      var opportunities = [];
      for (let index = 0; index <= 12; index++) {
        opportunities[index] = { 'ID': (index + 1), 'Name': this.$i18n.t("directInvest_Opportunities.title_" + (index + 1)), '% Funded': '', 'Interest Rate': this.$i18n.t("directInvest_Opportunities.rate_" + (index + 1)), 'Term (years)': this.$i18n.t("directInvest_Opportunities.term_" + (index + 1)), specialBenefit: '' };
      }
      return opportunities;
    }
  }
}
</script>

<style scoped>
.browse-btn {
  margin: auto;
  padding: 14px 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: grey;
  color: white;
}

.bordered {
  border: 1px solid black;
  margin-left: 0px;
  padding: 30px;
}

.bold {
  font-weight: 800;
}

.column-left {
  float: left;
  width: 50%;
}

.column-right {
  float: right;
  width: 50%;
}

.div-chapter {
  margin-bottom: 50px;
}
</style>