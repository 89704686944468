<template>
  <div id="userManagement">
    <h1>User Management</h1>
    <br />
    <table>
      <thead>
        <tr style="background-color: orange">
          <th>Name</th>
          <th>Email</th>
          <th>Address</th>
          <th>Job</th>
          <th>Active</th>
          <th>Suspend</th>
          <th>Activate</th>
          <th>Manage Role</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.Firstname }} {{ user.Lastname }}</td>
          <td>{{ user.Email }}</td>
          <td>
            {{ user.Country }} {{ user.State }} {{ user.City }}
            {{ user.Zipcode }}
          </td>
          <td>
            {{ user.Jobfunction }} {{ user.Joblevel }} {{ user.Jobtitle }}
          </td>
          <td>{{ user.Approved }}</td>
          <td>
            <button class="btn btn-danger" @click="suspendUser(user.Email)">
              Suspend
            </button>
          </td>
          <td>
            <button class="btn btn-primary" @click="activateUser(user.Email)">
              Activate
            </button>
          </td>
          <td>
            <button
              class="btn btn-info"
              @click="updateRole(user.Email, user.Rolemanage)"
            >
              {{ user.Rolemanage }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "../axios";
export default {
  data: function () {
    return {
      users: [],
    };
  },
  mounted() {
    this.getUsers();
  },

  methods: {
    getUsers() {
      axios
        .get("api/getUsers")
        .then((response) => {
          //   console.log(response);
          this.setupUsers(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setupUsers(res) {
      //   var key = 0;
      this.users = [];
      res.data.forEach((item) => {
        this.users.push(item);
      });
      // console.log(
      //   this.users.map((user, index) => ({ ...user, id: index + 1 }))
      // );
    },
    suspendUser(email) {
      if (confirm("Are you sure you want to suspend this user?")) {
        axios
          .post("api/suspendUsers", { email: email })
          .then(() => {
            // console.log(response);
            this.getUsers();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    activateUser(email) {
      if (confirm("Are you sure you want to activate this user?")) {
        axios
          .post("api/activateUsers", { email: email })
          .then(() => {
            // console.log(response);
            this.getUsers();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    updateRole(email, cur) {
      var toRole = "Registered User";
      if (cur == "Registered User") {
        toRole = "Admin";
      }
      if (
        confirm(
          "Are you sure you want to change role of this user to " + toRole + "?"
        )
      ) {
        axios
          .post("api/updateRole", { email: email, role: toRole })
          .then(() => {
            // console.log(response);
            this.getUsers();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
<style>
td,
th {
  border: 1px solid black;
}
</style>
