<template>
  <div id="volunteer" class="brand">
    <h1>{{ $t("volunteer.heading") }}</h1>

    <div class="container_volunteer">
      <div class="container_mid">
        <!-- title -->
        <h1 class="brand">
          <span>{{ $t("volunteer.neuro") }}</span>{{ $t("volunteer.leap") }}
        </h1>
        <div class="wrapper">
          <div class="volunteerForm">
            <div class="alert">
              {{ $t("volunteer.message_sent") }}
            </div>
            <form id="volunteerform" @submit.prevent="submitFormVolunteer">
              <b-container>
                <b-row>
                  <!-- firstname -->
                  <b-col sm>
                    <label>{{ $t("volunteer.volunteer_firstname") }} *</label>
                    <input id="txt_firstname" type="text" required name="txt_firstname" pattern="[a-zA-Z'-'\s]*" />
                  </b-col>
                  <!-- lastname -->
                  <b-col sm>
                    <label>{{ $t("volunteer.volunteer_lastname") }} *</label>
                    <input id="txt_lastname" type="text" required name="txt_lastname" pattern="[a-zA-Z'-'\s]*" />
                  </b-col>
                </b-row>
                <b-row>
                  <!-- city -->
                  <b-col sm>
                    <label>{{ $t("volunteer.volunteer_city") }} *</label>
                    <input id="txt_city" type="text" name="txt_city" required />
                  </b-col>
                  <!-- state -->
                  <b-col sm>
                    <label>{{ $t("volunteer.state") }} *</label>
                    <input id="txt_state" type="text" name="txt_state" required />
                  </b-col>
                  <!-- country -->
                  <b-col sm>
                    <label>{{ $t("volunteer.country") }} *</label>
                    <template>
                      <b-form-select id="drpdwn_country" name="drpdwn_country" v-model="selected_volunteer"
                        :options="getCountries()" required>
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled>{{
                            $t("countries.250")
                          }}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </template>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- address -->
                  <b-col sm>
                    <label>{{ $t("volunteer.address") }} *</label>
                    <input id="txt_address" type="text" name="txt_address" required />
                  </b-col>
                  <!-- zipcode -->
                  <b-col sm>
                    <label>{{ $t("volunteer.zipCode") }} *</label>
                    <input id="txt_zipcode" type="text" name="txt_zipcode" required />
                  </b-col>
                </b-row>
                <b-row>
                  <!-- phone number -->
                  <b-col sm>
                    <label>{{ $t("volunteer.volunteer_phone") }}</label>
                    <input id="nr_phone" type="number" name="nr_phone" />
                  </b-col>
                  <!-- email -->
                  <b-col sm>
                    <label>{{ $t("volunteer.volunteer_email") }} *</label>
                    <input id="email_address" type="email" required name="email_address" @keyup="input_check_email" />
                  </b-col>
                </b-row>
                <b-row>
                  <!-- pdf document -->
                  <b-col sm>
                    <div class="pdf-box">
                      <ul>
                        <li class="pdf-docu" v-for="item in pdf_details" v-bind:key="item.id">
                          <directInvest_Opportunities_Details_Pdf :id="item.id" :href="item.href"
                            :download="item.download" :filename="item.filename" />
                        </li>
                      </ul>
                    </div>
                  </b-col>
                  <!-- waiver upload -->
                  <b-col sm>
                    <label>{{ $t("volunteer.waiver_upload") }} *</label>
                    <input class="file_upload" id="file_volunteer_waiver" type="file" name="file_volunteer_waiver"
                      accept="application/pdf" required />
                  </b-col>
                </b-row>
                <b-row>
                  <!-- reason -->
                  <b-col>
                    <label>{{ $t("volunteer.volunteer_reason") }} *</label>
                    <textarea id="txt_reasonVolunteer" name="txt_reasonVolunteer" rows="3" />
                  </b-col>
                </b-row>
                <b-row>
                  <!-- contribution -->
                  <b-col>
                    <label>{{ $t("volunteer.volunteer_value") }} *</label>
                    <textarea id="txt_valueVolunteer" name="txt_valueVolunteer" rows="3" />
                  </b-col>
                </b-row>
                <b-row>
                  <!-- submit button -->
                  <b-col>
                    <button v-b-modal.modal-1 type="submit" class="btn_volunteer" name="submit" value="Submit Now">
                      {{ $t("volunteer.btn_submit") }}
                    </button>
                  </b-col>
                  <!-- reset button -->
                  <b-col>
                    <button type="reset" class="btn_volunteer">
                      {{ $t("volunteer.btn_reset") }}
                    </button>
                  </b-col>
                </b-row>
              </b-container>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div>
      <b-modal id="modal-1">
        <h2>Thank you for your submission!</h2>
        <br />
        <p>We will get back to you soon.</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import dataService from "../services/dataService";
import directInvest_Opportunities_Details_Pdf from "./directInvest_Opportunities_Details_Pdf.vue";
export default {
  components: { directInvest_Opportunities_Details_Pdf },
  name: "Volunteer",
  data() {
    return {
      pdf_details: [
        {
          id: 1,
          href: "neuro_Leap_Volunteer_Waiver.pdf",
          download: "neuro_Leap_Volunteer_Waiver.pdf",
          filename: "Volunteer Waiver",
        },
      ],
      selected_volunteer: null,
    };
  },
  methods: {
    // check if email is valid
    input_check_email: function () {
      if (
        /([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,})$/.test(
          document.getElementById("email_address").value
        ) == true
      ) {
        document.getElementById("email_address").setCustomValidity("");
      } else {
        document
          .getElementById("email_address")
          .setCustomValidity(this.$i18n.t("volunteer.email_check"));
        document.getElementById("email_address").reportValidity();
      }
    },
    // submit form
    submitFormVolunteer: function () {
      var firstName = document.getElementById("txt_firstname").value;
      var lastName = document.getElementById("txt_lastname").value;
      var mail = document.getElementById("email_address").value;
      var zipcode = document.getElementById("txt_zipcode").value;
      var cityText = document.getElementById("txt_city").value;
      var stateText = document.getElementById("txt_state").value;
      var addressText = document.getElementById("txt_address").value;
      var inputCountry = document.getElementById("drpdwn_country");
      var countryText = inputCountry.options[inputCountry.selectedIndex].text;
      var phoneNr = document.getElementById("nr_phone").value;
      var why = document.getElementById("txt_reasonVolunteer").value;
      var how = document.getElementById("txt_valueVolunteer").value;

      var user = {
        mail: mail,
        first_name: firstName,
        last_name: lastName,
        zipcode: zipcode,
        city: cityText,
        country: countryText,
        state: stateText,
        address: addressText,
        phone_nr: phoneNr,
      };

      this.sendToDataService(user, why, how);

      alert("Submit successful!");
    },
    // send data
    sendToDataService: function (user, why, how) {
      var fileReader = new FileReader();
      fileReader.onload = function (event) {
        dataService.createVolunteer(user, why, how, event.target.result);
      };
      fileReader.readAsDataURL(
        document.getElementById("file_volunteer_waiver").files[0]
      );
    },

    //get the countries for the country dropdown
    getCountries: function () {
      var countries = [];
      for (let index = 0; index <= 249; index++) {
        countries[index] = this.$i18n.t("countries." + index);
      }
      return countries;
    },
  },
};
</script>

<style scoped>
.file_upload {
  background-color: #fff;
}

.col-sm {
  margin: 0.5em;
}

.container_volunteer {
  width: 100%;
  background-color: #f4892f;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}

.container_mid {
  width: 100%;
}

h2 {
  margin: 0;
}

a {
  color: black;
}

/* VolunteerForm */

* {
  box-sizing: border-box;
}

body {
  background: #f9c75c;
  color: #f4892f;
  line-height: 1.6;
  padding: 1em;
}

.container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
}

.brand span {
  color: #fff;
}

.wrapper {
  box-shadow: 0 0 20px 0 rgba(243, 142, 19, 0.7);
}

.wrapper>* {
  padding: 1em;
}

.volunteerForm {
  background: #f9c75c;
}

/* FORM STYLES */

.container_mid form label {
  display: block;
}

.container_mid form p {
  margin: 0;
}

.container_mid form button,
.container_mid form input,
.container_mid form textarea,
.container_mid form select,
.container_mid form option {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #faa535;
}

.container_mid form button {
  background: #f4892f;
  float: left;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
}

.container_mid form button:hover,
.container_mid form button:focus {
  background: #b33d06;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
  cursor: pointer;
}

.alert {
  text-align: center;
  padding: 10px;
  background: #79c879;
  color: #fff;
  margin-bottom: 10px;
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* LARGE SCREENS */
@media (min-width: 700px) {
  .wrapper {
    display: grid;
  }

  .wrapper>* {
    padding: 2em;
  }

  .brand {
    text-align: left;
  }
}
</style>
