import axios from 'axios'

const instance = axios.create();
// require('dotenv').config();

instance.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
// instance.defaults.baseURL = "http://localhost:3000/"

// instance.defaults.baseURL = "http://nginx_proxy:80/"
instance.defaults.headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    // 'withCredetials': true
    // 'token': getToken('AUTHORIZATION_TOKEN_BLOG'),
    // 'refresh': getToken('REFRESH_TOKEN_BLOG')
}

export default instance