<template>
  <div class="footer-wrapper">
    <el-divider />
    <img src="../img/Logo.png" class="footer-logo" alt="logo" />

    <!-- start actual footer -->
    <div class="footer">
      <!-- footer column 1 -->
      <div class="footer-col">
        <ul class="footer-list">
          <!-- footer title -->
          <li class="title">
            {{ $t("FooterTitle[0]") }}
          </li>
          <!-- footer links -->
          <li>
            <a
              href="http://vividkidsblog.page"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn1[0]") }}</a
            >
          </li>
          <li>
            <a
              href="http://vividkidsblog.page"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn1[1]") }}</a
            >
          </li>
          <li>
            <a
              href="http://vividkidsblog.page"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn1[2]") }}</a
            >
          </li>
          <li>
            <a
              href="http://vividkidsblog.page"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn1[3]") }}</a
            >
          </li>
          <li>
            <a
              href="http://vividkidsblog.page"
              target="_blank"
              class="footer-list-link"
              >Mental Health</a
            >
          </li>
        </ul>
      </div>

      <!-- footer column 3 -->
      <div class="footer-col">
        <ul class="footer-list">
          <!-- footer title 3 -->
          <li class="title">
            {{ $t("FooterTitle[2]") }}
          </li>
          <!-- footer links -->
          <!-- link targets are pdf-files in the public-folder. :href instead of href and ${publicPath} used for that reason -->
          <li>
            <a
              :href="`${publicPath}nl-terms-and-conditions.pdf`"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn3[0]") }}</a
            >
          </li>
          <li>
            <a
              :href="`${publicPath}nl-privacy-policy-eu.pdf`"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn3[1]") }}</a
            >
          </li>
          <li>
            <a
              :href="`${publicPath}nl-privacy-policy.pdf`"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn3[2]") }}</a
            >
          </li>
          <li>
            <a
              :href="`${publicPath}nl-cookie-policy.pdf`"
              target="_blank"
              class="footer-list-link"
              >{{ $t("FooterColumn3[3]") }}</a
            >
          </li>
        </ul>
      </div>

      <!-- footer column 4 -->
      <!-- <div class="footer-col">
        <ul class="footer-list">
          <li class="title">
            {{ $t('FooterTitle[3]') }}
          </li>
          <li>
            <a href="https://www.nvidia.com/" target="_blank" class="footer-list-link">{{ $t('FooterColumn4[0]') }}</a>
          </li>
          <li>
            <a href="https://www.pie.capital/" target="_blank" class="footer-list-link">{{ $t('FooterColumn4[1]') }}</a>
          </li>
          <li>
            <a href="https://myactionspot.com/" target="_blank" class="footer-list-link">{{ $t('FooterColumn4[2]')
            }}</a>
          </li>
        </ul>
      </div> -->
    </div>

    <el-divider />
    <div class="copyright">
      The entire neuroleap.com website is Copyright &copy;2015-2022 by Neuro
      Leap Corporation. All Rights Reserved. |
      <a
        :href="`${publicPath}nl-privacy-policy.pdf`"
        target="_blank"
        class="footer-list-link"
        >{{ $t("FooterColumn3[2]") }}</a
      >
    </div>
  </div>
</template>

<script>
/* used to path to pdf-files in public-folder */
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped>
.footer-wrapper {
  width: 100%;
  text-align: center;
}

.footer-logo {
  height: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.footer-col {
  width: auto;
  width: 250px;
  text-align: left;
  display: inline-block;
  vertical-align: top;
}

.footer-list {
  list-style-type: none;
}

.title {
  font-weight: bold;
  margin-bottom: 20px;
}

a,
a:link,
a:visited,
a:hover {
  color: black;
}

li {
  margin-bottom: 5px;
}

.copyright {
  font-size: 10px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
