<template>
  <div id="directInvest_Login">
    <h1>Login Test Page</h1>

    <div class="container_directInvest_Login">
      <div class="container_mid">
        <h1 class="brand">
          <span>{{ $t("directInvest_Login.neuro") }}</span
          >{{ $t("directInvest_Login.leap") }}
        </h1>
        <div class="wrapper">
          <div class="directInvest_LoginForm">
            <form id="directInvest_Login" @submit.prevent="verifyLogin">
              <b-container>
                <b-row>
                  <b-col sm="6">
                    <label>{{ $t("directInvest_Login.username") }} *</label>
                    <input
                      id="usernamedirectInvest_Login"
                      type="text"
                      required
                      name="usernamedirectInvest_Login"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <label>{{ $t("directInvest_Login.password") }} *</label>
                    <input
                      id="passworddirectInvest_Login"
                      type="password"
                      required
                      name="passworddirectInvest_Login"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <button
                      type="submit"
                      class="btn_directInvest_Login"
                      name="submit"
                      value="Submit Now"
                    >
                      {{ $t("directInvest_Login.btn_signIn") }}
                    </button>
                  </b-col>
                  <b-col sm="3">
                    <router-link to="/directinvest/signup">
                      <button
                        type="link"
                        id="signUp"
                        class="btn_directInvest_Login"
                        name="signUp"
                      >
                        {{ $t("directInvest_Login.btn_signUp") }}
                      </button>
                    </router-link>
                  </b-col>
                </b-row>
                <b-row class="signIn_label">
                  <b-col sm="6">
                    <label>{{ $t("directInvest_Login.noAccount") }}</label>
                  </b-col>
                </b-row>
              </b-container>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataService from "../services/dataService";

export default {
  name: "directInvest_Login",
  methods: {
    verifyLogin: function () {
      var username = document.getElementById(
        "usernamedirectInvest_Login"
      ).value;
      var password = document.getElementById(
        "passworddirectInvest_Login"
      ).value;

      dataService
        .verifyLoginInvestmentPortal(username, password)
        .then((res) => {
          if (res.data.Username === undefined) {
            console.log(res);
            alert("Incorrect Username or password!");
          } else {
            this.$emit("loggedIn", res.data);
            this.$router.push({ path: "/directinvest/opportunities" });
          }
        });
    },
  },
};
</script>

<style scoped>
.pdf-btn {
  margin: 0px 10px;
  padding: 14px 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: blue;
  text-decoration: none;
  color: white;
}
.upload-btn {
  padding: 7px 8px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: orange;
  text-decoration: none;
  color: white;
}

/* CSS for contact form */
.col-sm-3 {
  margin-top: 0.75em;
}

.col-sm label {
  margin-top: 0.5em;
  text-align: right;
}

.signIn_label {
  text-align: right;
}
.container_directInvest_Login {
  width: 100%;
  background-color: #f4892f;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}

.container_mid {
  width: 100%;
}
h2 {
  margin: 0;
}
a {
  color: black;
}
.mail_crypt {
  unicode-bidi: bidi-override;
  direction: rtl;
}

/* VolunteerForm */

* {
  box-sizing: border-box;
}

body {
  background: #f9c75c;
  color: #f4892f;
  line-height: 1.6;
  padding: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
}

.brand span {
  color: #fff;
}

.wrapper {
  box-shadow: 0 0 20px 0 rgba(243, 142, 19, 0.7);
}

.wrapper > * {
  padding: 1em;
}

.directInvest_LoginForm {
  background: #f9c75c;
}

/* FORM STYLES */

.container_mid form label {
  display: block;
}

.container_mid form p {
  margin: 0;
}

.container_mid form button,
.container_mid form input,
.container_mid form textarea,
.container_mid form select,
.container_mid form option {
  width: 100%;
  padding: 1em;
  border: 1px solid #faa535;
}

.container_mid form button {
  background: #f4892f;
  float: left;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
  width: 100%;
}

.container_mid form button:hover,
.container_mid form button:focus {
  background: #b33d06;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
  cursor: pointer;
}

.alert {
  text-align: center;
  padding: 10px;
  background: #79c879;
  color: #fff;
  margin-bottom: 10px;
  display: none;
}

/* LARGE SCREENS */
@media (min-width: 700px) {
  .wrapper {
    display: grid;
  }

  .wrapper > * {
    padding: 2em;
  }

  .brand {
    text-align: left;
  }
}
</style>
