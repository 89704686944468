<template>
  <div id="fieldTrialVideo">
    <div class="video-around">
      <div class="video">
        <div class="background1">
          <img class="bubbles" background-image src="../img/About_content_Graph1.png" alt="About_content_Graph1" />
        </div>
        <div class="video-text">
          <div class="line-video">
            <h2>{{ $t("fieldTrialVideo.header") }}</h2>

            <div v-if="isLoggedIn" class="body-video">
              <iframe width="420" height="240" src="https://www.youtube.com/embed/8fxVQeGII8s">
              </iframe>
            </div>

            <div v-else>
              <iframe class="blankFrame" width="420" height="240" id="blankiframe" />
            </div>
          </div>
        </div>
        <div class="background2">
          <img class="bubbles" background-image src="../img/About_content_Graph2.png" alt="About_content_Graph2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fieldTrialVideo",
  data() {
    return {};
  },

  mounted: function () {
    var doc = document.getElementById("blankiframe").contentWindow.document;
    doc.open();
    doc.write("Please login to view this demo field trial video.");
    doc.close();
  },

  computed: {
    isLoggedIn() {
      var logged = this.$store.getters.isLoggedIn;
      return logged;
      // return true
    },
  },
};
</script>

<style scoped>
#fieldTrialVideo:target {
  padding-top: 7vh;
}

@media screen and (max-width: 1397px) and (min-width: 860px) {
  #fieldTrialVideo:target {
    padding-top: 15vh;
  }
}

.blankFrame {
  background-color: gray;
}

.video-around {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5%;
}

.video {
  vertical-align: top;
  position: relative;
  overflow: visible;
  min-height: 18.75rem;
}

.background1 {
  position: absolute;
  width: 25%;
  overflow-x: hidden;
  z-index: 1;
}

.background2 {
  position: absolute;
  width: 25%;
  margin-left: 75%;
  bottom: -60%;
  overflow-x: hidden;
  overflow-y: visible;
  z-index: 3;
}

.video-text {
  position: absolute;
  margin-top: 6%;
  margin-left: 12%;
  margin-right: 15%;
  width: 75%;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow-x: hidden;
  z-index: 2;
  background-color: white;
  min-height: 100px;
}

.line-video {
  margin-top: 5%;
  margin-bottom: 10%;
  margin-left: 10%;
  margin-right: 12%;
}

.bubbles {
  width: 100%;
}

.headline-video {
  text-align: center;
  margin: 0px;
}

.body-video {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .video-text {
    min-height: 260px;
  }
}
</style>
