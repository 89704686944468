<template>
  <div>
    <h1>Reset Password</h1>
    <form @submit.prevent="handleSubmit">
      <div>Email: {{ email }}</div>
      <div>
        <label for="password">New Password:</label>
        <input type="password" id="password" v-model="password" required />
      </div>
      <div>
        <label for="confirmPassword">Confirm Password:</label>
        <input
          type="password"
          id="confirmPassword"
          v-model="confirmPassword"
          required
        />
      </div>
      <button type="submit">Reset Password</button>
    </form>
  </div>
</template>

<script>
import instance from "../axios";
export default {
  data() {
    return {
      email: "", // Holds the email extracted from the URL
      password: "",
      confirmPassword: "",
    };
  },
  created() {
    // Extract the email parameter from the URL when the component is created
    const params = new URLSearchParams(window.location.search);
    this.email = params.get("email") || ""; // Default to empty string if no email is provided
  },
  methods: {
    handleSubmit() {
      if (this.password !== this.confirmPassword) {
        this.message = "Passwords do not match!";
        return;
      }
      var timestamp = new Date().getTime().toString();
      const data = {
        email: this.email,
        password: this.password,
        timestamp: timestamp,
      };
      instance
        .post("/submit_password", data)
        .then(function (response) {
          if (response.data) {
            // console.log(response.data);
            alert("Password reset done");
          }
        })
        .catch(function () {
          // console.log(error);
          alert(
            "You have already reset password within one day or there are other issues with our service, please try again later"
          );
        });
    },
  },
};
</script>
