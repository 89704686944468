<template>
  <div id="thankyoupage">
    <br /><br />
    <h2>Thank you for your submission!</h2>
    <br />
    <p>We will get back to you soon.</p>
  </div>
</template>

<script>
export default {
  mounted: function () {
    this.goTop();
  },

  methods: {
    goTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped></style>
