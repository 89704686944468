<template>
  <!-- main nav container -->
  <div id="directInvest_nav" class="navbar">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

    <!-- nav container left: logo -->
    <div id="myNav-logo" class="nav-logo">
      <router-link to="/">
        <a href="#"><img src="../img/Logo1.png" alt="logo1" href="#" width="60px" @click="hideNavResponsive()"></a>
      </router-link>
      <!-- link for burger-icon (mobile nav): with css only displayed when mobile screen, on click do function navResponsive() -->
      <a href="javascript:void(0);" class="icon" @click="navResponsive()">
        <i class="fa fa-bars" />
      </a>
    </div>

    <!-- nav container mid: links -->
    <div id="myNav-links" class="nav-links" @click="comboFunction()">
      <router-link to="/supportus">
        <a @click="hideNavAndScroll()">Back to Support Us</a>
      </router-link>
      <router-link to="/directinvest">
        <a @click="hideNavAndScroll()">How it works</a>
      </router-link>

      <router-link to="/directinvest/opportunities">
        <a @click="hideNavAndScroll()">Opportunities</a>
      </router-link>
    </div>

    <!-- nav container right: buttons -->
    <div id="myNav-btns" class="nav-btns">
      <label>{{ usernameDirect }}</label>
      <router-link to="/directinvest/login">
        <button class="nav-btn btn-donate">Login
        </button>
      </router-link>
      <router-link to="/directinvest/signup">
        <!-- Original Value: {{ $t("Header[8]") }} -->
        <button class="nav-btn btn-login">
          Sign up
        </button>
      </router-link>
      <!-- language buttons, shows all languages in /locales folder as options -->
      <select v-model="$i18n.locale" class="nav-btn btn-language" @change="hideNavResponsive()">
        <option v-for="(lang, i) in $i18n.availableLocales" :key="`Lang${i}`" :value="lang" class="btn-option">
          {{ lang }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>

export default {
  //props: ['username'],
  props: {
    usernameDirect: {
      type: String,
      default: 'username'
    }
  },
  methods: {
    // when burger-icon of mobile nav is clicked, add ".responsive" to logo, links and button classes
    // allows to style elements differently when mobile screen
    navResponsive: function () {
      // nav-logo
      var x = document.getElementById("myNav-logo");
      if (x.className === "nav-logo") {
        x.className += " responsive";
      } else {
        x.className = "nav-logo";
      }
      // nav-links
      var y = document.getElementById("myNav-links");
      if (y.className === "nav-links") {
        y.className += " responsive";
      } else {
        y.className = "nav-links";
      }
      // nav-buttons
      var z = document.getElementById("myNav-btns");
      if (z.className === "nav-btns") {
        z.className += " responsive";
      } else {
        z.className = "nav-btns";
      }
    },
    // hide mobile nav when nav-element has been clicked (hide content / elements, show only navbar)
    hideNavResponsive: function () {
      // nav-logo
      var x = document.getElementById("myNav-logo");
      x.className = "nav-logo";
      // nav-links
      var y = document.getElementById("myNav-links");
      y.className = "nav-links";
      // nav-buttons
      var z = document.getElementById("myNav-btns");
      z.className = "nav-btns";
      // emit nav-change to global EventBus, will be caught by Slider and slide content will be updated (for language change)
      //EventBus.$emit('nav-change');
    },
    routeToHome: function () {
      this.$router.push({ path: '/' });
    },
    hideNavAndScroll: function () {
      this.hideNavResponsive();
      window.scrollTo(0, 0);
    },
    comboFunction: function () {
      this.hideNavResponsive();
      //this.routeToHome();
    }
  },
}
</script>

<style scoped>
/* MAIN CONTAINERS */
/* | nav-logo |    | nav-links |    | nav-btns | */
.navbar {
  width: 100%;
  background-color: white;
  position: fixed;
  z-index: 10;
  text-align: center;
  border: 1px solid #DCDFE6;
}

.nav-logo {
  width: auto;
  float: left;
  display: block;
}

.nav-links {
  display: inline-block;
  margin: 0 auto;
  padding-top: 16px;
}

.nav-btns {
  float: right;
}

/* Elements inside the containers */
.nav-links a {
  color: black;
  text-align: center;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 16px;
  padding-top: 16px;
  text-decoration: none;
  font-size: 17px;
}

.nav-btn {
  margin-right: 10px;
  margin-top: 5px;
  padding: 14px 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

/* Button classes */
.btn-login {
  color: white;
  background-color: #f4892f;
}

.btn-donate {
  color: white;
  background-color: #bd4594;
}

.btn-language {
  color: white;
  background-color: grey;
}

.btn-option {
  background-color: grey;
  color: white;
}

/* Change the color of links on hover */
.nav-links a:hover {
  background-color: #ddd;
  color: black;
}

/* Hide the link (burger icon) of mobile nav when it is not a small screen */
.nav-logo .icon {
  display: none;
}

/* Make nav elements a bit smaller after 1080 px to not make it overlap, provides still normal nav on most tablets */
@media screen and (max-width: 1080px) {
  .nav-links a {
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .nav-btns .nav-btn {
    font-size: 13px;
    padding: 10px;
    margin-top: 10px;
  }
}

/* When the screen is less than 860 pixels wide, hide all links and buttons. Show the link (burger icon) that should be used as mobile navigation */
@media screen and (max-width: 860px) {
  .nav-links {
    display: none;
  }

  .nav-btns {
    display: none;
  }

  .nav-logo {
    display: block;
  }

  .nav-logo a.icon {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 14px;
    padding-top: 16px;
    color: grey;
  }
}

/* The "responsive" class is added to the nav-elements with JavaScript function "navResponsive() when the user clicks on the burger icon. These classes make the elements display vertically instead of horizontally on small screens makes the nav-links look good on small screens */
@media screen and (max-width: 860px) {
  .nav-links.responsive {
    position: relative;
    display: block;
  }

  .nav-logo.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .nav-links.responsive a {
    float: none;
    display: block;
    text-align: left;
    border-bottom: 1px solid #DCDFE6;
  }

  .nav-logo.responsive {
    float: none;
    display: block;
    text-align: left;
    border-bottom: 1px solid #DCDFE6;
  }

  .nav-btns.responsive {
    float: none;
    display: block;
    text-align: left;
  }

  .nav-btns.responsive .nav-btn {
    float: left;
    text-align: left;
    display: block;
    padding: 14px 16px;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .nav-links {
    padding-top: 0px;
  }
}
</style>