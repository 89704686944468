<template>
  <div id="questionnaireform">
    <!-- title -->
    <div id="questionnaire_form_header" class="questionnaire_form_header text-center">
      <h3><b>{{ $t("fieldTrialQuestionnaire.header") }}</b></h3>
    </div>

    <div class="questionnaire_form_body">
      <!-- start field trial form -->
      <div>
        <div class="questionnaire_form_wrapper">
          <form id="contactForm" method="post" action="https://www1.ipage.com/scripts/formemail.html">
            <div v-if="currentSlide <= 3" class="text-dark">
              <!-- header -->
              <h4 style="text-align: center; padding-left: 25px;">
                <label id="fieldtrail_questions"><b>{{ getQuestion().header }} </b></label>
              </h4>
              <br />
              <br />
              <!-- question slides -->
              <b-form-group style="padding-left: 50px;">
                <b-form-radio-group v-if="currentSlide != 5" v-model="getQuestion().selectedAnswer"
                  :options="getQuestion().answerOptions" name="radios-stacked" stacked class="text-dark" size="lg">
                </b-form-radio-group>

                <b-form-checkbox-group v-else v-model="getQuestion().selectedAnswers"
                  :options="getQuestion().answerOptions" stacked></b-form-checkbox-group>
              </b-form-group>
              <div>
                <el-button round @click="updateSlide('back')" class="text-dark" v-show="currentSlide != 0"><b>Back</b>
                </el-button>
                <el-button round @click="updateSlide('next')" class="text-dark">
                  <b>Next</b>
                </el-button>
              </div>
            </div>

            <!-- last slide -->
            <div v-if="currentSlide == 4">
              <PercentageCircle :percent="getPercent()" size="big"
                style="float: left; margin-right: 10%; margin-bottom: 4%;" active-color="blue" complete-color="green" />

              <p>
                <b>
                  {{ $t("fieldTrialQuestionnaire.scorePt1") }} {{ getPercent() }} {{ $t("fieldTrialQuestionnaire.scorePt2") }}</b>
              </p>
              <br />

              <p>
                <b>{{ $t("fieldTrialQuestionnaire.thankuMessage") }}</b>
              </p>
              <el-button round @click="updateSlide('reset')" class="text-dark">
                <b>{{ $t("fieldTrialQuestionnaire.retry") }}</b>
              </el-button>
            </div>


            <img class="questionnaire_image" height="500" width="500" src="../img/Psychologist_1.png"
              alt="Psychologist_1" />
          </form>
        </div>
      </div>

      <!-- end contactform -->
    </div>
  </div>
</template>

<script>
import PercentageCircle from "vue-css-percentage-circle";

export default {
  name: "fieldTrialQuestionnaire",
  components: {
    PercentageCircle,
  },

  data() {
    return {
      currentSlide: 0,

      questions: [
        {
          header: "Do you know what percent of kids in the US have dyslexia?",
          answerOptions: ["a) 23", "b) 15"],
          selectedAnswer: null,
          correctAnswer: "b) 15",
        },

        {
          header:
            "What percent of US students ages 3-21 received special education services under IDEA?",
          answerOptions: ["a) 14%", "b) 20%", "c) 10%", "d) 5%"],
          selectedAnswer: null,
          correctAnswer: "a) 14%",
        },

        {
          header: "Which is the most common learning disability in childhood?",
          answerOptions: [
            "a) ADHD",
            "b) Dyslexia",
            "c) Cerebral palsy",
            "d) Autism",
          ],
          selectedAnswer: null,
          correctAnswer: "b) Dyslexia",
        },

        {
          header: "Which is the most common motor disability in childhood?",
          answerOptions: [
            "a) Muscular dystrophy",
            "b) Multiple Sclerosis",
            "c) Spina bifida",
            "d) Cerebral palsy",
          ],
          selectedAnswer: null,
          correctAnswer: "d) Cerebral palsy",
        },
      ],
    };
  },

  methods: {
    updateSlide(action) {
      if (action == "back") {
        if (this.currentSlide > 0) this.currentSlide = this.currentSlide - 1;
      } else if (action == "next") {
        if (this.currentSlide < 4) this.currentSlide = this.currentSlide + 1;
      }
      else if (action == "reset") {
        this.currentSlide = this.currentSlide - 4
      }
    },

    getQuestion() {
      if (this.currentSlide <= 3) return this.questions[this.currentSlide];
    },

    getPercent() {
      var i = 0;
      var numCorrectlyAnswered = 0;

      for (i = 0; i < 4; i++) {
        if (
          this.questions[i].selectedAnswer == this.questions[i].correctAnswer
        ) {
          numCorrectlyAnswered++;
        }
      }

      var length = 4;
      var percent = Math.round((numCorrectlyAnswered / length) * 100);
      return percent;
    },
  },
};
</script>

<style scoped>
#questionnaireform:target {
  padding-top: 7vh;
}

@media screen and (max-width: 1397px) and (min-width: 860px) {
  #questionnaireform:target {
    padding-top: 15vh;
  }
}

.el-button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.questionnaire_image {
  position: absolute;
  width: 25%;
  margin-left: -24%;
  overflow-x: hidden;
  overflow-y: visible;
  z-index: 3;
}

.questionnaire_form_wrapper {
  width: 100%;
  height: 450px;
  border: 1px solid #dcdfe6;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.questionnaire_form_body {
  width: 100%;
  border: 1px solid #dcdfe6;
  margin-bottom: 70px;
}

.questionnaire_form_body form {
  display: grid;
  grid-gap: 10px;
  min-height: 350px;
  overflow: auto;
  margin-bottom: 20px;
}

.questionnaire_form_body label {
  display: block;
}

.questionnaire_form_body p {
  margin: 0;
}

.questionnaire_form_body .full {
  grid-column: 1 / 3;
}

.questionnaire_form_body form input,
.questionnaire_form_body textarea,
.questionnaire_form_body form select,
.questionnaire_form_body form option {
  width: 100%;
  height: 50%;
  padding: 1em;
  border: 1px solid #e6e6e6;
  background-color: #e6e6e6;
}

.questionnaire_form_body form button {
  float: left;
  width: 40%;
  margin-right: 35px;
  box-shadow: 1px 1px 2px #333;
}

.questionnaire_form_body form button:hover,
.questionnaire_form_body form button:focus {
  color: #fff;
  outline: 0;
  border-color: 1px solid black;
  cursor: pointer;
}

.questionnaire_form_header {
  background-color: #f4892f;
  color: white;
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 10px;
}

/* LARGE SCREENS */
@media (min-width: 700px) {
  .field_trial_wrapper {
    display: grid;
  }

  .field_trial_wrapper>* {
    padding: 2em;
  }

  .questionnaire_form_wrapper {
    display: grid;
  }

  .questionnaire_form_wrapper>* {
    padding-left: 13em;
    padding-right: 13em;
    padding-top: 3em;
  }

  .company-info h3,
  .company-info ul,
  .brand {
    text-align: left;
  }
}

@media screen and (max-width: 601px) {
  .questionnaire_form_wrapper {
    display: block;
  }

  .field_trial_wrapper {
    display: block;
  }
}
</style>
