import App from "./App";

export default [
  { path: "/", name: "App", component: App },
  { path: "/fieldTrial", name: "fieldTrial", component: App },
  { path: "/login", name: "login", component: App },
  { path: "/register", name: "register", component: App },

  { path: "/supportus", name: "supportUs", component: App },
  { path: "/thankyoupage", name: "thankyoupage", component: App },
  { path: "/donate", name: "donate", component: App },
  { path: "/equityinvest", name: "equityinvest", component: App },
  { path: "/volunteer", name: "volunteer", component: App },
  { path: "/refercustomer", name: "refercustomer", component: App },
  { path: "/directinvest", name: "directinvest", component: App },
  { path: "/directinvest/opportunities", name: "directinvest/opportunities", component: App },
  { path: "/directinvest/login", name: "directinvest/login", component: App },
  { path: "/directinvest/signup", name: "directinvest/signup", component: App },
  { path: '/directinvest/:id', name: "directinvest/id", component: App },
  { path: '/partner', name: "partner", component: App },
  { path: '/events', name: "events", component: App },
  { path: '/userManagement', name: "userManagement", component: App },
  { path: '/registeredEvents', name: "registeredEvents", component: App },
  { path: '/resetPassword', name: "resetPassword", component: App },


]