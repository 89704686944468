<template>
  <div id="whyneuroLeap" class="whyNeuroLeap">
    <h1>{{ $t("whyNeuroLeap.heading") }}</h1>
    <img
      src="../img/3rdOnLandingPage.jpeg"
      alt="happy_hero"
      style="
        width: 60%;
        height: auto;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 5%;
      "
    />
    <p style="font-size: large">
      At NeuroLeap, we offer a truly end-to-end AI-powered solution that
      combines cutting-edge hardware and software to revolutionize both
      neurological care and educational support. Our platform is uniquely
      positioned to meet the complex needs of healthcare providers and
      school-based specialists by delivering real-time, adaptive, and
      personalized interventions/specialized lessons that evolve with each
      patient and student.
    </p>

    <button class="collapsible">Comprehensive AI Solutions</button>
    <div class="collapsible_content">
      NeuroLeap stands out as one of the few companies offering an integrated
      platform that combines Edge-AI hardware (motion sensors, biosensors) with
      Cloud-AI software (advanced algorithms for data analysis). This seamless
      integration provides real-time insights for both neurological treatment
      and educational development.
    </div>

    <button class="collapsible">Precision and Personalization</button>
    <div class="collapsible_content">
      Our platform is designed to adapt to the individual needs of each user.
      Whether you're treating a patient with ADHD, traumatic brain injury (TBI),
      cerebral palsy (CP), or supporting a student with speech, literacy,
      fine-motor skills, or cognitive challenges, NeuroLeap delivers tailored
      interventions that adjust dynamically based on real-time data.
    </div>

    <button class="collapsible">
      Dual Focus on Neurological and Educational Care
    </button>
    <div class="collapsible_content">
      Unlike other AI companies that focus on a single sector, NeuroLeap
      provides solutions across both healthcare and education. Our platform is
      built to serve hospitals, clinics, rehabilitation centers, and schools,
      ensuring that patients and students receive the most effective care and
      support possible.
    </div>

    <button class="collapsible">Real-Time, Adaptive Technology</button>
    <div class="collapsible_content">
      NeuroLeap’s AI-powered platform continuously monitors progress and adjusts
      treatment plans or learning paths in real time. This dynamic approach
      helps optimize outcomes for patients and students by constantly refining
      interventions based on actual performance.
    </div>

    <button class="collapsible">Scalable and Customizable</button>
    <div class="collapsible_content">
      Our solutions are designed to scale across different environments, from
      large hospitals to specialized rehabilitation centers and educational
      institutions. We offer customizable platforms that can be tailored to fit
      the specific needs of each organization, ensuring flexibility and
      long-term value.
    </div>

    <button class="collapsible">Cutting-Edge AI and Cloud Integration</button>
    <div class="collapsible_content">
      By integrating edge AI processing with cloud-based scalability, NeuroLeap
      ensures fast, secure, and FERPA/HIPAA-compliant data handling. Our
      technology provides healthcare providers and school-based specialists with
      immediate, actionable insights while also supporting the long-term storage
      and analysis of complex datasets.
    </div>

    <button class="collapsible">Commitment to Innovation</button>
    <div class="collapsible_content">
      NeuroLeap is at the forefront of AI innovation in healthcare and
      education. Since 2015, we continuously push the boundaries of what's
      possible with AI, staying ahead of industry trends and incorporating the
      latest advances in technology to improve care and learning outcomes.
    </div>

    <!-- <button class="collapsible">
      {{ $t("whyNeuroLeap.collapse_1_title") }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t("whyNeuroLeap.collapse_1_content") }}</p>
    </div>

    <button class="collapsible">
      {{ $t("whyNeuroLeap.collapse_2_title") }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t("whyNeuroLeap.collapse_2_content") }}</p>
    </div>

    <button class="collapsible">
      {{ $t("whyNeuroLeap.collapse_3_title") }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t("whyNeuroLeap.collapse_3_content") }}</p>
    </div> -->
  </div>
</template>

<script>
export default {
  // global function to make collapsible effect
  // gives every class called "collapsible" the actual collapsible effect for the next element
  mounted: function () {
    // For Donation
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://www.paypal.com/sdk/js?client-id=Acm_FNoZJuwPQTLbA-0NqylATBlhwanM71KkAGjAb9ryO_yrzsNv5NZgeg2XdHE1Y9IRGasT2KpmLhZm&enable-funding=venmo&currency=USD"
    );
    document.head.appendChild(externalScript);
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
        }
      });
    }
  },
};
</script>

<style scoped>
#whyneuroLeap:target {
  padding-top: 7vh;
}

@media screen and (max-width: 1397px) and (min-width: 860px) {
  #whyneuroLeap:target {
    padding-top: 15vh;
  }
}
</style>
