<template>
  <div id="products" class="products">
    <img
      id="providers"
      src="../img/child_in_wheelchair.jpg"
      alt=""
      style="border-radius: 5%"
      width="53%"
    />
    <!-- <h1>{{ $t("Products") }}</h1> -->
    <h1>Solutions: AI-Driven Neurological and Educational Care</h1>
    <br />
    <p>
      <strong style="font-size: x-large">AI-Powered Neurological Care:</strong
      ><br />
      Our AI platform is designed to enhance patient outcomes by providing
      criterion-based screenings, norm-referenced/criterion-referenced dynamic
      assessments and personalized interventions for neuropsychological and
      neurodevelopmental (such as autism spectrum disorder and ADHD) conditions.
      We specialize in the care of patients with mental health issues, TBI, CP,
      and other acquired brain injuries, delivering adaptive treatments based on
      real-time data.
    </p>
    <p>
      <strong style="font-size: x-large">Neurological Assessments: </strong
      ><br />
      Early detection and continuous monitoring of motor and cognitive
      impairments for conditions like TBI and CP.
    </p>
    <img
      id="schools"
      src="../img/girl_open_door.png"
      alt=""
      style="border-radius: 5%"
      width="53%"
    />
    <p>
      <strong style="font-size: x-large">AI-Powered Educational Care:</strong
      ><br />

      NeuroLeap also extends its platform to the educational sector, offering
      criterion-based screenings, norm-referenced/criterion-referenced dynamic
      assessments and personalized interventions for speech and language
      development, literacy, cognitive skills, motor skills and emotional &
      interpersonal health. Our AI-powered tools adapt dynamically in real time
      to each student’s progress, providing tailored educational support.
    </p>

    <p>
      <strong style="font-size: x-large">Educational Interventions: </strong
      ><br />Personalized learning paths and interventions for speech, language,
      literacy, cognitive skills, and emotional well-being.
    </p>

    <!-- Slider: displayed slides defined in <script> of this component -->
    <!-- Vueper Slides is an imported component from the internet. Examples and API here: https://antoniandre.github.io/vueper-slides/ -->
    <vueper-slides
      bullets-outside
      :key="isLoggedIn"
      ref="productSlider"
      fade
      slide-content-outside="bottom"
    >
      <vueper-slide
        id="vueperslides"
        v-for="(slide, i) in slides"
        :key="i"
        :title="slide.title"
        :image="slide.img"
        :btn1href="slide.btn1href"
        :btn2href="slide.btn2href"
        style="
          background-size: 27% auto;
          background-position: center;
          background-repeat: no-repeat;
        "
      >
        <!-- content -->
        <div slot="slideContent">
          <!-- {{ $t(slide.txtKey + "[1]") }} -->
          <!-- buttons with link -->
          <!-- each button will only be displayed if href of button has value (see <script> of this component, slide data there) -->
          <div class="slide-ref">
            <!-- button 1 -->
            <div v-if="slide.btn1href != ''" class="slide-ref-btn">
              <a v-if="i == 9 || i == 10 || i == 11" :href="slide.btn1href">
                <el-button id="firstbtn" round>{{
                  $t(slide.txtKey + "[2]")
                }}</el-button>
              </a>
              <a v-else :href="slide.btn1href" target="_blank">
                <el-button id="firstbtn" round>{{
                  $t(slide.txtKey + "[2]")
                }}</el-button>
              </a>
            </div>
            <div v-else class="slide-ref-btn" />
            <!-- button 2 -->
            <div v-if="slide.btn2href != ''" class="slide-ref-btn">
              <span v-if="isLoggedIn">
                <a :href="slide.btn2href" target="_blank">
                  <el-button round>Demo Video</el-button>
                </a></span
              >

              <span v-else>
                <el-button id="secondbtn" round
                  >Please login to view this demo video.</el-button
                >
              </span>
            </div>
            <div v-else class="slide-ref-btn" />
          </div>
        </div>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { EventBus } from "../bus.js";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  components: { VueperSlides, VueperSlide },
  // slide data

  computed: {
    isLoggedIn() {
      var logged = this.$store.getters.isLoggedIn;
      return logged;
    },

    slides() {
      var logged = this.$store.getters.isLoggedIn;
      // console.log(logged);
      var slides = [
        // txtKey used for reference to translation-files (folder "locales")

        {
          // SLP Screener 2
          title: `<strong>${this.$t("Product5[0]")}</strong><br>${this.$t(
            "Product5[1]"
          )}`,
          // content: "<div v-if='slide.btn1href != ''' class='slide-ref-btn'><a :href='slide.btn1href' target='_blank'><el-button id='firstbtn' round>{{$t(slide.txtKey + '[2]')}}</el-button></a></div>",
          txtKey: "Product5",
          img: require("../img/products-intervention-1.png"),

          btn1href:
            "https://apps.apple.com/us/app/slp-screener2-comprehensive-language-articulation/id1088148414?l=ja&ls=1",
          btn2href: "",
        },

        {
          // Motor Skills Screener 2 App
          title: `<strong>Motor Skills Screener 2 App</strong> <br> Comprehensive Motor Skills Screening is an iPad app built specifically for Physical Therapists and Occupational Therapists to use with children. It allows the  Physical Therapists (PT) and Occupational Therapists (OT) to easily select categories, ask to perform various tasks, questions, and rate the responses from the student/patient. Video recording files can be automatically added as an email attachment. It offers a quick but thorough understanding of the patients' & students’ motor skills.`,
          txtKey: "Product10",
          img: require("../img/running.png"),

          btn1href: "https://apps.apple.com/us/app",
          btn2href: "",
        },

        {
          // Speech & Language Assessment
          title: `${this.$t("Product6[0]")}<br>${this.$t("Product6[1]")}`,
          txtKey: "Product6",
          img: require("../img/talking.png"),

          btn1href: "http://vividkidsblog.page/products",
          btn2href: "https://youtu.be/M8vPBLmd3Ks",
        },
        {
          // Speech & Language Intervention
          title: `${this.$t("Product7[0]")}<br>${this.$t("Product7[1]")}`,
          txtKey: "Product7",
          img: require("../img/talking.png"),

          btn1href: "http://vividkidsblog.page/products",
          btn2href: "",
        },
        {
          // Reading & Writing Assessment
          title: `${this.$t("Product8[0]")}<br>${this.$t("Product8[1]")}`,
          txtKey: "Product8",
          img: require("../img/book.png"),

          btn1href: "http://vividkidsblog.page/products",
          btn2href: "",
        },
        {
          // Reading & Writing Intervention
          title: `${this.$t("Product9[0]")}<br>${this.$t("Product9[1]")}`,
          txtKey: "Product9",
          img: require("../img/book.png"),

          btn1href: "http://vividkidsblog.page/products",
          btn2href: "",
        },
        {
          // Motor Skills Assessment
          title: `${this.$t("Product10[0]")}<br>${this.$t("Product10[1]")}`,
          txtKey: "Product10",
          img: require("../img/running.png"),

          btn1href: "http://vividkidsblog.page/products",
          btn2href: "",
        },
        {
          // Motor Skills Intervention
          title: `${this.$t("Product11[0]")}<br>${this.$t("Product11[1]")}`,
          txtKey: "Product11",
          img: require("../img/running.png"),

          btn1href: "http://vividkidsblog.page/products",
          btn2href: "",
        },
        {
          // Cognitive Assessment
          title: `${this.$t("Product12[0]")}<br>${this.$t("Product12[1]")}`,
          txtKey: "Product12",
          img: require("../img/brain.png"),

          btn1href: "http://vividkidsblog.page/products",
          btn2href: "",
        },
        {
          // Cognitive Intervention
          title: `${this.$t("Product13[0]")}<br>${this.$t("Product13[1]")}`,
          txtKey: "Product13",
          img: require("../img/brain.png"),

          btn1href: "http://vividkidsblog.page/products",
          btn2href: "",
        },
        {
          // EI
          title: `Emotional Intelligence & Interpersonal Skills (Mental Health)`,
          txtKey: "Product13",
          img: require("../img/brain.png"),

          btn1href: "http://vividkidsblog.page/products",
          btn2href: "",
        },
        {
          title: `${this.$t("Product1[0]")}<br>${this.$t("Product1[1]")}`,
          txtKey: "Product1",
          img: logged
            ? require("../img/products-nl1-1.png")
            : require("../img/nl-login.png"),
          btn1href: "#contactus",
          btn2href: "https://youtu.be/8fxVQeGII8s",
        },
        {
          title: `${this.$t("Product2[0]")}<br>${this.$t("Product2[1]")}`,
          txtKey: "Product2",
          img: logged
            ? require("../img/products-nl1-2.png")
            : require("../img/nl-login.png"),
          btn1href: "#contactus",
          btn2href: "https://youtu.be/8fxVQeGII8s",
        },
        {
          title: `${this.$t("Product3[0]")}<br>${this.$t("Product3[1]")}`,
          txtKey: "Product3",
          img: logged
            ? require("../img/products-nl1-3.png")
            : require("../img/nl-login.png"),
          btn1href: "#contactus",
          btn2href: "https://youtu.be/8fxVQeGII8s",
        },
        {
          title: `${this.$t("Product4[0]")}<br>${this.$t("Product4[1]")}`,
          txtKey: "Product4",
          img: logged
            ? require("../img/products-nlcloud-1.png")
            : require("../img/nl-login.png"),

          btn1href: "https://www.nl1.cloud",
          btn2href: "",
        },
      ];

      return slides;
    },
  },
  mounted() {
    window.onload = function () {
      var bullets = document.getElementsByClassName("vueperslides__bullet");
      for (var i = 0; i < bullets.length; i++) {
        bullets[i].style.backgroundColor = "gray";
      }
    };
  },
  // make component listen to global EventBus, will catch Event 'nav-change' from header-component
  // reason: after language change slider still displayed content in wrong language
  created() {
    EventBus.$on("nav-change", this.updateSlider());
  },

  // watch: {
  //   displayProducts(newValue) {
  //     console.log("watch new val: ", newValue);

  //     var i = 0;
  //     for (i = 0; i < this.slides.length; i++) {
  //       this.slides[i].img = newValue
  //         ? require("../img/products-intervention-5.png")
  //         : require("../img/grayed-out.jpg");
  //     }
  //   },
  // },

  methods: {
    updateSlider: function () {
      this.$forceUpdate();
      // this.$refs.productSlider.init();
      // scroll to top, otherwise focus would be always on slider after update
      window.scrollTo(0, 0);
    },
    // test: function () {

    // }
  },
};
</script>

<style scoped>
#products:target {
  padding-top: 7vh;
}

@media screen and (max-width: 1397px) and (min-width: 860px) {
  #products:target {
    padding-top: 15vh;
  }
}

.products {
  width: 100%;
  margin: auto;
  display: block;
  clear: both;
}

h1 {
  text-align: left;
}

h2 {
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.el-button {
  margin-top: 10px;
  margin-bottom: 10px;
  color: black;
}

#firstbtn {
  background-color: #f4892f;
}

#secondbtn {
  border-color: #f4892f;
}

.slide-ref-btn {
  float: left;
  margin-right: 10px;
}

.vueperslides__bullet .default {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 16px;
  height: 16px;
}

.vueperslides__bullet--active .default {
  background-color: #42b983;
}

.vueperslides__bullet span {
  display: block;
  color: #fff;
  font-size: 10px;
  opacity: 0.8;
}
</style>
