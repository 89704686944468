<template>
  <div id="events">
    <h1>{{ loggedUsername }}</h1>
    <h1>{{ $t("NewsEvents.title") }}</h1>

    <!-- filter toggle -->
    <div id="toggle_bar">
      <div class="mb-3">
        <!-- show news filter -->
        <div class="form-check" style="float: left">
          <input
            class="form-check-input center"
            type="checkbox"
            id="show_news"
            name="show_news"
            v-model="show_news"
          />
          <label class="form-check-label center" for="show_news">
            {{ $t("NewsEvents.filter.showNews") }}
            &nbsp; &nbsp;
          </label>
        </div>
        <!-- show events filter -->
        <div class="form-check" style="float: left">
          <input
            class="form-check-input center"
            type="checkbox"
            id="show_events"
            name="show_events"
            v-model="show_events"
          />
          <label class="form-check-label center" for="show_events">
            {{ $t("NewsEvents.filter.showEvents") }}
          </label>
        </div>
        <!-- show more filters -->
        <b-button
          v-b-toggle.my-collapse
          style="float: right; border-radius: 16px; height: 50px"
          @click="isCollapsed = !isCollapsed"
        >
          &nbsp;
          <span v-if="isCollapsed">{{ $t("NewsEvents.filter.search") }}</span>
          <span v-else>{{ $t("NewsEvents.filter.search2") }}</span>
          <span style="font-size: 25px">&#8801;</span>
          &nbsp;
        </b-button>
        <!-- update -->
        <button
          type="button"
          @click="update()"
          style="
            background-color: #f4892f;
            color: white;
            height: 50px;
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 16px;
            float: right;
          "
          class="btn"
        >
          Update
        </button>
      </div>
    </div>
    <!-- more filters -->
    <b-collapse id="my-collapse">
      <b-card>
        <table v-if="show_events || show_news">
          <!-- date -->
          <tr>
            <td VALIGN="TOP">{{ $t("NewsEvents.filter.DATE.date") }}:</td>
            <td style="white-space: nowrap">
              <!-- start date -->
              <VueDatePicker
                style="display: inline-block"
                v-model="filter_start_date"
                fullscreen-mobile
              />
              To
              <!-- end date -->
              <VueDatePicker
                style="display: inline-block"
                :min-date="filter_start_date"
                v-model="filter_end_date"
                fullscreen-mobile
              />
              <button
                @click="
                  filter_start_date = null;
                  filter_end_date = null;
                "
                style="
                  background-color: #f4892f;
                  color: white;
                  padding-left: 10px;
                  padding-right: 10px;
                  border-radius: 16px;
                  border: 1px solid #f4892f;
                "
              >
                clear
              </button>
            </td>
          </tr>
          <br />
          <!-- age -->
          <tr>
            <td VALIGN="TOP">{{ $t("NewsEvents.filter.AGE.age") }}:</td>
            <td>
              <div v-if="!filter_for_all_age">
                <!-- start -->
                <input
                  type="number"
                  min="0"
                  @keypress="filterInput($event)"
                  @input="
                    if (filter_age_start < 0) {
                      filter_age_start = 0;
                    }
                  "
                  v-model.number="filter_age_start"
                />
                &nbsp;{{ $t("NewsEvents.filter.AGE.to") }}
                &nbsp;
                <!-- end -->
                <input
                  type="number"
                  min="0"
                  @keypress="filterInput($event)"
                  @input="
                    if (filter_age_end < 0) {
                      filter_age_end = 0;
                    }
                  "
                  v-model="filter_age_end"
                />
                {{ $t("NewsEvents.filter.AGE.year") }}
              </div>
              <input type="checkbox" v-model="filter_for_all_age" />For All Ages
            </td>
          </tr>
          <br />
          <!-- format -->
          <tr v-if="show_news">
            <td VALIGN="TOP">{{ $t("NewsEvents.filter.FORMAT.format") }}:</td>
            <td>
              <select name="format" id="format" v-model="filter_format">
                <option value=""></option>
                <option value="News Article">News Article</option>
                <option value="Youtube Video">Youtube Video</option>
                <option value="Magazine">Magazine</option>
                <option value="Tutorial/Quickstart Guide">
                  Tutorial/Quickstart Guide
                </option>
                <option value="News Website">News Website</option>
                <option value="White Paper">White Paper</option>
              </select>
            </td>
          </tr>
          <br v-if="show_events" />
          <!-- industry -->
          <tr>
            <td VALIGN="TOP">Industry:</td>
            <td>
              <multiselect
                v-model="filter_industry"
                :options="industries"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
              >
              </multiselect>
            </td>
          </tr>
          <br />
          <!-- classification -->
          <tr>
            <td VALIGN="TOP">
              {{ $t("NewsEvents.filter.classificationTitle") }}:
            </td>
            <td>
              <multiselect
                v-model="filter_class"
                :options="classes"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
              >
              </multiselect>
            </td>
          </tr>
          <br />
          <!-- Geo Area -->
          <tr>
            <td VALIGN="TOP">Region:</td>
            <td>
              <select name="area" id="area" v-model="filter_geo_area">
                <option value=""></option>
                <option value="World Wide">World Wide</option>
                <option value="Africa">
                  {{ $t("NewsEvents.filter.GeoArea.Africa") }}
                </option>
                <option value="Asia">
                  {{ $t("NewsEvents.filter.GeoArea.Asia") }}
                </option>
                <option value="Caribbean">
                  {{ $t("NewsEvents.filter.GeoArea.Caribbean") }}
                </option>
                <option value="Central America">
                  {{ $t("NewsEvents.filter.GeoArea.Central America") }}
                </option>
                <option value="Europe">
                  {{ $t("NewsEvents.filter.GeoArea.Europe") }}
                </option>
                <option value="North America">
                  {{ $t("NewsEvents.filter.GeoArea.North America") }}
                </option>
                <option value="Oceania">
                  {{ $t("NewsEvents.filter.GeoArea.Oceania") }}
                </option>
                <option value="South America">
                  {{ $t("NewsEvents.filter.GeoArea.South America") }}
                </option>
              </select>
            </td>
          </tr>
          <br />
          <!-- location -->
          <tr v-if="show_events">
            <td VALIGN="TOP">Location:</td>
            <td>
              <vueCountriesCities
                @country="filter_country = $event"
                @city="filter_city = $event"
              />
              <button
                @click="
                  filter_country = '';
                  filter_city = '';
                "
                style="
                  background-color: #f4892f;
                  color: white;
                  padding-left: 10px;
                  padding-right: 10px;
                  border-radius: 16px;
                  border: 1px solid #f4892f;
                "
              >
                clear
              </button>
            </td>
          </tr>
          <!-- price range -->
          <tr v-if="show_events">
            <td VALIGN="TOP">Price Range:</td>
            <td>
              <div v-if="!filter_free_event_only">
                <input type="number" v-model="filter_price_min" />
                to
                <input type="number" v-model="filter_price_max" />
              </div>
              <input type="checkbox" v-model="filter_free_event_only" />Free
              Events Only
            </td>
          </tr>
          <br />
        </table>
      </b-card>
    </b-collapse>

    <div id="left_column">
      <!-- space handle news/events -->
      <br />
      <!-- display news -->

      <div id="news_column">
        <h1 v-show="show_news">News:<br /></h1>
        <div v-for="(news, index) in old_news" :key="index">
          <div
            @click="openToggleNews(index)"
            v-show="show_news"
            v-if="!news.isHidden"
            style="
              border: 1px solid #ced8d9;
              margin: 5px;
              padding-left: 20px;
              background-color: #8cb7df;
              border-radius: 5px;
              display: flex;
              min-height: 110px;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <div>
              <h1 style="font-style: italic">{{ news.Title }}</h1>
              <h3>{{ news.Subtitle }}</h3>
              <!-- industry -->
              <span v-if="news.Industry != '[]'"
                >Related industry:&nbsp;
                <span
                  v-for="(item, index) in JSON.parse(news.Industry)"
                  :key="index"
                  >{{ item
                  }}<span v-if="index < JSON.parse(news.Industry).length - 1"
                    >,
                  </span>
                </span>

                <br />
              </span>
              <br />
            </div>
            <!-- <span style="font-size: x-large">&#43; &nbsp;</span> -->
            <img
              v-if="news.image_data"
              :src="'data:image/jpeg;base64,' + news.image_data"
              alt="image"
              height="100px"
              width="125px"
              style="
                position: relative;
                float: right;
                top: 0;
                right: 0;
                margin-top: 2px;
                margin-right: 5px;
              "
            />
          </div>
          <div
            :id="`openToggleNews${index}`"
            v-if="!news.isHidden"
            class="appendNews"
          >
            <!-- image -->
            <img
              v-if="news.image_data"
              :src="'data:image/jpeg;base64,' + news.image_data"
              alt="image"
              height="200px"
              width="250px"
              style="
                position: absolute;
                top: 0;
                right: 0;
                margin-top: 2px;
                margin-right: 5px;
              "
            /><br />
            <!-- format -->
            <span v-if="news.Format">
              <strong>Format: </strong> {{ news.Format }}<br
            /></span>
            <!-- link -->
            <span v-if="news.Link">
              <strong>Link: </strong> {{ news.Link }}<br
            /></span>
            <!-- region -->
            <span v-if="news.Region">
              <strong>Region: </strong> {{ news.Region }}<br
            /></span>
            <!-- age range -->
            <span v-if="news.AllAge"> <strong>For all ages</strong><br /></span>
            <span v-else-if="news.AgeStart == 0 && news.AgeEnd == 0"></span>
            <span v-else>
              <strong>Age range: </strong> {{ news.AgeStart }} to
              {{ news.AgeEnd }} years old<br
            /></span>
            <!-- class -->
            <span v-if="news.Class != '[]'">
              <strong>Related class: </strong>
              <span v-for="(item, index) in JSON.parse(news.Class)" :key="index"
                >{{ item
                }}<span v-if="index < JSON.parse(news.Class).length - 1"
                  >,
                </span>
              </span>
              <br />
            </span>
            <!-- industry -->
            <span v-if="news.Industry != '[]'">
              <strong>Related industry:&nbsp;</strong>
              <span
                v-for="(item, index) in JSON.parse(news.Industry)"
                :key="index"
                >{{ item
                }}<span v-if="index < JSON.parse(news.Industry).length - 1"
                  >,
                </span>
              </span>
              <br />
            </span>
            <!-- description -->
            <strong>Details: </strong>
            <span
              style="word-wrap: break-word"
              v-if="news.Description"
              v-html="news.Description"
            ></span>
            <!-- likes -->
            <span v-if="news.Likes"
              ><button @click="likeNews(news.Timestamp)" class="upvoteButton">
                <i class="fa fa-thumbs-up">{{ " " + news.Likes }}</i></button
              ><br
            /></span>
            <!-- download file -->
            <button
              class="download-btn"
              v-if="news.doc_data"
              style="background-color: #f4892f"
              @click="downloadFile(news.doc_data, news.DocFormat)"
            >
              Download File
            </button>
          </div>
        </div>
      </div>

      <!-- display events -->
      <div id="event_column">
        <h1 v-show="show_events">Events: <br /></h1>
        <div v-for="(event, index) in old_events" :key="index">
          <div
            @click="openToggleEvents(index)"
            v-show="show_events"
            v-if="!event.isHidden"
            style="
              border: 1px solid #ced8d9;
              margin: 5px;
              padding-left: 20px;
              background-color: #f4892f;
              border-radius: 5px;
              min-height: 150px;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <!-- date range -->
            <span
              style="
                font-style: italic;
                float: right;
                right: 10px;
                position: relative;
                color: white;
              "
              v-if="
                event.DateStart != 'null' && event.DateStart != event.DateEnd
              "
              >{{ formatDate(event.DateStart) }} - {{ formatDate(event.DateEnd)
              }}<br />
            </span>
            <span
              style="
                font-style: italic;
                float: right;
                right: 10px;
                position: relative;
                color: white;
              "
              v-else-if="
                event.DateStart != 'null' && event.DateStart == event.DateEnd
              "
              >{{ formatDate(event.DateStart) }}</span
            >
            <!-- title -->
            <span style="font-size: x-large">{{ event.Title }}<br /></span>
            <img
              v-if="event.image_data"
              :src="'data:image/jpeg;base64,' + event.image_data"
              alt="image"
              height="100px"
              width="125px"
              style="
                position: relative;
                float: right;
                top: 0;
                right: 0;
                margin-top: 2px;
                margin-right: 5px;
              "
            />
            <!-- subtitle -->
            <span v-if="event.Subtitle"> {{ event.Subtitle }}<br /> </span>
            <!-- industry -->
            <span v-if="event.Industry != '[]'"
              >Related industry:&nbsp;
              <span
                v-for="(item, index) in JSON.parse(event.Industry)"
                :key="index"
                >{{ item
                }}<span v-if="index < JSON.parse(event.Industry).length - 1"
                  >,
                </span>
              </span>
              <br />
            </span>

            <!-- <span style="font-size: x-large">&#43; &nbsp;</span> -->
          </div>

          <div
            :id="`openToggleEvents${index}`"
            v-if="!event.isHidden"
            class="appendEvent"
          >
            <!-- img -->
            <img
              v-if="event.image_data"
              :src="'data:image/jpeg;base64,' + event.image_data"
              alt="image"
              height="200px"
              width="250px"
              style="
                position: absolute;
                top: 0;
                right: 0;
                margin-top: 2px;
                margin-right: 5px;
              "
            /><br />
            <!-- industry -->
            <span v-if="event.Industry != '[]'">
              <strong>Related industry: &nbsp;</strong>
              <span
                v-for="(item, index) in JSON.parse(event.Industry)"
                :key="index"
                >{{ item
                }}<span v-if="index < JSON.parse(event.Industry).length - 1"
                  >,
                </span>
              </span>
              <br />
            </span>
            <!-- region -->
            <span v-if="event.Region">
              <strong>Region:</strong> {{ event.Region }}<br
            /></span>
            <!-- class -->
            <span v-if="event.Class != '[]'">
              <strong>Related class:</strong>
              <span
                v-for="(item, index) in JSON.parse(event.Class)"
                :key="index"
                >{{ item
                }}<span v-if="index < JSON.parse(event.Class).length - 1"
                  >,
                </span>
              </span>
              <br />
            </span>

            <!-- age range -->
            <span v-if="event.AllAge">
              <strong>For all ages</strong> <br
            /></span>
            <span v-else-if="event.AgeStart == 0 && event.AgeEnd == 0"></span>
            <span v-else>
              <strong>Age range:</strong> {{ event.AgeStart }} to
              {{ event.AgeEnd }} years old<br
            /></span>
            <!-- date range -->
            <span v-if="event.DateStart != 'null'">
              <strong>Date:</strong> {{ formatDate(event.DateStart) }} to
              {{ formatDate(event.DateEnd) }}<br
            /></span>
            <!-- time range -->
            <span v-if="event.AllDay">
              <strong>For all day</strong> <br
            /></span>
            <span
              v-else-if="event.TimeStart == '' || event.TimeEnd == ''"
            ></span>
            <span v-else>
              <strong>Time range:</strong> {{ event.TimeStart }} to
              {{ event.TimeEnd }}<br />
            </span>
            <!-- timezone -->
            <span v-if="event.TimeZone">
              <strong>Timezone:</strong> {{ event.TimeZone }}<br
            /></span>
            <!-- number of expected people coming for event -->
            <span v-if="event.NumExpect != 0">
              <strong>Number of People Expected:</strong> {{ event.NumExpect
              }}<br
            /></span>
            <!-- cost -->
            <span v-if="event.NoCost"> <strong>Free event</strong> <br /></span>
            <span v-else-if="event.Cost == 0"></span>
            <span v-else-if="event.Currency == 'USD'">
              <strong>Cost:</strong> {{ event.Currency }} ${{ event.Cost }} <br
            /></span>
            <span v-else>
              <strong>Cost:</strong> {{ event.Cost }} {{ event.Currency }} <br
            /></span>

            <!-- location -->
            <span v-if="event.Country && event.City"
              ><strong>Location:</strong> {{ event.Country }}, {{ event.City }}.
              {{ event.MeetingType }}<br
            /></span>

            <!-- sponsor -->
            <span v-if="event.Sponsor != ''">
              This event is sponsored by {{ event.Sponsor }}<br
            /></span>
            <span v-else> This event is not sponsored <br /></span>
            <!-- description -->
            <strong> Details:</strong>

            <span
              style="word-wrap: break-word"
              v-if="event.Description"
              v-html="event.Description"
            ></span>
            <!-- item list -->
            <div v-if="event.ItemList != '[]'">
              <strong>Item list:</strong>
              <ul>
                <li
                  v-for="(item, index) in JSON.parse(event.ItemList)"
                  :key="index"
                >
                  {{ item.amount }} {{ item.unit }} {{ item.name }}
                </li>
              </ul>
              <br />
            </div>
            <!-- likes -->
            <span v-if="event.Likes"
              ><button @click="likeEvent(event.Timestamp)" class="upvoteButton">
                <i class="fa fa-thumbs-up">{{ " " + event.Likes }}</i></button
              ><br
            /></span>
            <!-- download file -->
            <button
              class="download-btn"
              v-if="event.doc_data"
              style="background-color: #f4892f"
              @click="downloadFile(event.doc_data, event.DocFormat)"
            >
              Download File
            </button>
            <br />
            <!-- signup for event -->
            <b-button
              v-if="!isInRegisteredEvents(event.Timestamp)"
              @click="openModal('modal-' + index + event.Title)"
              style="background-color: #8cb7df; color: black"
              ><i class="fa fa-plus"></i> Signup</b-button
            >
            <b-modal
              :id="'modal-' + index + event.Title"
              title="Signup"
              hide-footer
            >
              <div class="my-4">
                <h4>Firstname*:</h4>
                <input type="text" v-model="participant_firstname" />
                <h4>Lastname*:</h4>
                <input type="text" v-model="participant_lastname" />
                <h4>Email*:</h4>
                <input type="text" v-model="participant_email" />
                <br />

                <b-button v-b-modal.modal-4
                  >Add a Guest<i class="fa fa-plus"></i
                ></b-button>
                <br />
                <div id="guests_column"></div>
                <button
                  @click="
                    sendTransEmail(
                      participant_email,
                      event.Timestamp,
                      'modal-' + index + event.Title
                    )
                  "
                >
                  Submit
                </button>
              </div>
            </b-modal>
          </div>
          <!-- <h4>Location*:</h4>
              <select
                name="location"
                id="location"
                v-model="right_column_location"
              >
                <option value="Zoom link">Zoom</option>
                <option value="phone number">Phone call</option>
                <option value="Skype id">Skype</option>
                <option value="Whatsapp number">Whatsapp</option>
                <option value="in-person event address">In-person event</option>
                <option value="other way to contact">Other</option>
              </select> -->

          <!-- <br />
              <span v-show="right_column_location != ''">
                Please enter {{ right_column_location }}
                <br />
                <textarea
                  rows="3"
                  cols="33"
                  v-model="right_column_location_detail"
                ></textarea>
                <br />
                Provide details for hybrid locations:
                <textarea
                  rows="3"
                  cols="33"
                  v-model="right_column_location_detail_hybrid"
                ></textarea>
              </span> -->

          <!-- <br />
              <h4>How were we connected?</h4>
              <input type="text" />
              <h4>I am an interested:</h4>
              <div id="role">
                <select
                  v-model="role_designation"
                  name="interestgroup"
                  id="interestgroup"
                >
                  <option>CEO</option>
                  <option>CTO</option>
                  <option>CFO</option>
                  <option>{{ $t("contactform.role_law") }}</option>
                  <option>{{ $t("contactform.role_specialist") }}</option>
                  <option>{{ $t("contactform.role_healthcare") }}</option>
                  <option>{{ $t("contactform.role_representive") }}</option>
                  <option>{{ $t("contactform.role_teacher") }}</option>
                  <option>{{ $t("contactform.role_parent") }}</option>
                  <option>{{ $t("contactform.role_PCP") }}</option>
                  <option>{{ $t("contactform.role_press") }}</option>
                  <option>{{ $t("contactform.role_investor") }}</option>
                  <option>{{ $t("contactform.role_volunteer") }}</option>
                  <option>{{ $t("contactform.role_supporter") }}</option>
                  <option>{{ $t("contactform.role_others") }}</option>
                </select>
              </div> -->
        </div>
      </div>
    </div>

    <div id="right_column">
      <!-- calendar -->
      <div name="calendar" style="padding-top: 3vh">
        <VueDatePicker
          inline
          @onChange="eventDatePicker"
          v-model="calendarDate"
          :allowed-dates="allowedDates"
        />
        <div v-for="(e, index) in calendarEvents" :key="index">
          <ul style="background-color: #f4892f">
            <li>{{ e.Title }}</li>
          </ul>
        </div>
      </div>

      <h2 v-if="!participant_mode">{{ $t("NewsEvents.popup.title") }}</h2>
      <br />
      <!-- signup participants -->

      <!-- add news popup -->
      <b-button
        v-if="admin_mode"
        v-b-modal.modal-1
        style="background-color: #8cb7df"
        ><i class="fa fa-plus"></i> &nbsp;{{
          $t("NewsEvents.popup.News.addNews")
        }}
      </b-button>
      <b-modal
        ok-only
        size="xl"
        id="modal-1"
        class="popup_body_news"
        @hide="clearOnModalHiddenNews"
        title="News Detail"
      >
        <div class="my-4">
          <div>
            <div
              class="popup_body_news_content"
              style="background-color: #8cb7df; padding: 15px; float: left"
            >
              <h2>{{ $t("NewsEvents.popup.News.section1") }}</h2>
              <table>
                <tr>
                  <td>Title:*</td>
                  <td><input type="text" v-model="newspop.news_title" /></td>
                </tr>
                <tr>
                  <td>Subtitle:*</td>
                  <td><input type="text" v-model="newspop.news_subtitle" /></td>
                </tr>
                <tr>
                  <td>Format:</td>
                  <td>
                    <select
                      name="newsFormat"
                      id="newsFormat"
                      v-model="newspop.news_format"
                    >
                      <option value="News Article">News Article</option>
                      <option value="Youtube Video">Youtube Video</option>
                      <option value="Magazine">Magazine</option>
                      <option value="Tutorial/Quickstart Guide">
                        Tutorial/Quickstart Guide
                      </option>
                      <option value="News Website">News Website</option>
                      <option value="White Paper">White Paper</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Links:</td>
                  <td>
                    <input
                      type="text"
                      placeholder="link to external sources"
                      v-model="newspop.news_links"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Region:</td>
                  <td>
                    <select
                      name="newsRegion"
                      id="newsRegion"
                      v-model="newspop.news_region"
                    >
                      <option value="World Wide">World Wide</option>
                      <option value="Africa">Africa</option>
                      <option value="Asia">Asia</option>
                      <option value="Caribbean">Caribbean</option>
                      <option value="Central America">Central America</option>
                      <option value="Europe">Europe</option>
                      <option value="North America">North America</option>
                      <option value="Oceania">Oceania</option>
                      <option value="South America">South America</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Classification/Phase:</td>
                  <td>
                    <multiselect
                      v-model="newspop.news_class"
                      :options="newspop.news_classes"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                    >
                    </multiselect>
                  </td>
                </tr>
                <tr>
                  <td>Age range:</td>
                  <td>
                    <div v-if="!newspop.news_for_all_age">
                      <input type="number" v-model="newspop.news_age_start" />
                      &nbsp;to&nbsp;
                      <input type="number" v-model="newspop.news_age_end" />
                      &nbsp;
                    </div>

                    <input
                      type="checkbox"
                      v-model="newspop.news_for_all_age"
                    />For All Ages
                  </td>
                </tr>
                <tr>
                  <td>Industry:*</td>
                  <td>
                    <multiselect
                      v-model="newspop.news_industry"
                      :options="newspop.news_industries"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                    >
                    </multiselect>
                  </td>
                </tr>
                <tr>
                  <td>Details:*</td>
                  <td>
                    <quillEditor
                      style="
                        padding-bottom: 50px;
                        height: auto;
                        max-width: 800px;
                      "
                      ref="myQuillEditor"
                      :options="editorOption"
                      v-model="newspop.news_description"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Upload Images:</td>
                  <td>
                    <input
                      type="file"
                      id="upload_news"
                      name="img"
                      accept="image/*"
                      @change="loadFile($event)"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Upload Docs(pdf/doc/docx/xls/xlsx)</td>
                  <td>
                    <input
                      type="file"
                      id="upload_docs_news"
                      accept=".pdf,.doc,.docx,.xls,.xlsx"
                      @change="loadDoc($event)"
                    />
                  </td>
                </tr>
              </table>
            </div>
            <button
              type="button"
              style="background-color: #8cb7df"
              class="btn btn-lg"
              id="create_news"
              @click="appendNews($event)"
            >
              {{ $t("NewsEvents.popup.News.confirm") }}
            </button>
          </div>
        </div>
      </b-modal>
      <br />
      <br />
      <!-- add event popup -->
      <b-button
        v-if="!participant_mode"
        v-b-modal.modal-2
        style="background-color: #f4892f"
        ><i class="fa fa-plus"></i> &nbsp;{{
          $t("NewsEvents.popup.Events.addEvents")
        }}</b-button
      >
      <b-modal
        ok-only
        size="xl"
        id="modal-2"
        @hide="clearOnModalHiddenEvents"
        title="Event Detail"
      >
        <div style="width: 1100px">
          <div style="background-color: #f4892f; padding: 15px; float: left">
            <h2>{{ $t("NewsEvents.popup.Events.section1") }}</h2>
            <table>
              <tr>
                <td>Title:*</td>
                <td><input type="text" v-model="eventpop.event_title" /></td>
              </tr>
              <tr>
                <td>Subtitle:*</td>
                <td><input type="text" v-model="eventpop.event_subtitle" /></td>
              </tr>
              <tr>
                <td>Age range:</td>
                <td>
                  <div v-if="!eventpop.event_for_all_age">
                    <input type="number" v-model="eventpop.event_age_start" />
                    &nbsp;to&nbsp;
                    <input type="number" v-model="eventpop.event_age_end" />
                  </div>
                  <input
                    type="checkbox"
                    v-model="eventpop.event_for_all_age"
                  />For All Ages
                </td>
              </tr>
              <tr>
                <td>Industry:*</td>
                <td>
                  <multiselect
                    v-model="eventpop.event_industry"
                    :options="eventpop.event_industries"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                  >
                  </multiselect>
                </td>
              </tr>
              <tr>
                <td>Date range:*</td>
                <td>
                  <VueDatePicker
                    style="display: inline-block"
                    v-model="eventpop.event_start_date"
                    fullscreen-mobile
                  />
                  to
                  <VueDatePicker
                    style="display: inline-block"
                    :min-date="eventpop.event_start_date"
                    v-model="eventpop.event_end_date"
                    fullscreen-mobile
                  />
                </td>
              </tr>
              <tr>
                <td>Meeting Type:*</td>
                <td>
                  <select
                    name="eventType"
                    id="eventType"
                    v-model="eventpop.event_meeting_type"
                  >
                    <option value="remote">Remote</option>
                    <option value="inPerson">In-Person</option>
                    <option value="Hybrid">Hybrid</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Region:</td>
                <td>
                  <select
                    name="eventRegion"
                    id="eventRegion"
                    v-model="eventpop.event_region"
                  >
                    <option value="World Wide">World Wide</option>
                    <option value="Africa">Africa</option>
                    <option value="Asia">Asia</option>
                    <option value="Caribbean">Caribbean</option>
                    <option value="Central America">Central America</option>
                    <option value="Europe">Europe</option>
                    <option value="North America">North America</option>
                    <option value="Oceania">Oceania</option>
                    <option value="South America">South America</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Address:</td>
                <td>
                  <vueCountriesCities
                    @country="eventpop.event_country = $event"
                    @city="eventpop.event_city = $event"
                  />
                </td>
              </tr>
              <tr>
                <td>Sponsored:</td>
                <td>
                  <input type="input" v-model="eventpop.event_sponsored" />
                </td>
              </tr>
              <tr>
                <td>Time:</td>
                <td>
                  <div v-if="!eventpop.event_all_day">
                    <vue-timepicker
                      v-model="eventpop.event_time_start"
                      format="hh:mm a"
                      close-on-complete
                      manual-input
                    >
                    </vue-timepicker>
                    to
                    <vue-timepicker
                      v-model="eventpop.event_time_end"
                      format="hh:mm a"
                      close-on-complete
                      manual-input
                    >
                    </vue-timepicker>
                  </div>
                  <input type="checkbox" v-model="eventpop.event_all_day" />All
                  day event
                </td>
              </tr>
              <tr>
                <td>Timezone:</td>
                <td>
                  <div id="event_timezone">
                    <select @change="handleTimezoneChange" :value="timezone">
                      <option v-for="(zone, index) in timezones" :key="index">
                        {{ zone }}
                      </option>
                    </select>
                    <br />
                    Local time: {{ result && result.toString() }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Classification/Phase</td>
                <td>
                  <multiselect
                    v-model="eventpop.event_class"
                    :options="eventpop.event_classes"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                  >
                  </multiselect>
                </td>
              </tr>
              <tr>
                <td>Number of people expected:</td>
                <td>
                  <input
                    type="number"
                    v-model="eventpop.event_number_expected"
                  />
                </td>
              </tr>
              <tr>
                <td>Cost:</td>
                <td>
                  <input
                    v-if="!eventpop.event_free_cost"
                    type="number"
                    v-model="eventpop.event_cost"
                    placeholder="Cost to attend the event"
                  />
                  <select
                    name="currency"
                    id="currency"
                    v-model="eventpop.event_currency"
                    v-if="!eventpop.event_free_cost"
                  >
                    <option value="USD">USD $</option>
                    <option value="CAD">CAD $</option>
                    <option value="EUR">EUR €</option>
                    <option value="JPY">JPY ￥</option>
                    <option value="GBP">GBP £</option>
                    <option value="AUD">AUD A$</option>
                    <option value="CNY">CNY ￥</option>
                    <option value="AED">AED د.إ</option>
                    <option value="KRW">KRW ₩</option>
                    <option value="RUB">RUB ₽</option>
                    <option value="ESP">ESP Pes.</option>
                    <option value="SEK">SEK kr</option>
                    <option value="VND">VND D.</option>
                  </select>
                  <input
                    type="checkbox"
                    v-model="eventpop.event_free_cost"
                  />This event is free
                </td>
              </tr>
              <tr>
                <td>Details:</td>
                <td>
                  <quillEditor
                    style="padding-bottom: 50px; height: auto; max-width: 800px"
                    ref="myQuillEditor"
                    :options="editorOption"
                    v-model="eventpop.event_description"
                  />
                </td>
              </tr>
              <tr>
                <td>Upload Image:</td>
                <td>
                  <input
                    type="file"
                    id="upload"
                    name="img"
                    accept="image/*"
                    @change="loadFile($event)"
                  />
                </td>
              </tr>
              <tr>
                <td>Upload Docs(pdf/doc/docx/xls/xlsx)</td>
                <td>
                  <input
                    type="file"
                    id="upload_docs_event"
                    accept=".pdf,.doc,.docx,.xls,.xlsx"
                    @change="loadDoc($event)"
                  />
                </td>
              </tr>
            </table>

            <!-- <tr>
                <td>{{ $t("NewsEvents.popup.Events.location.title") }}:</td>
                <td>
                  <select name="locations" id="locations" v-model="event_location">
                    <option value="Zoom link">{{ $t("NewsEvents.popup.Events.location.format[0]") }}</option>
                    <option value="phone number">{{ $t("NewsEvents.popup.Events.location.format[1]") }}</option>
                    <option value="Skype id">{{ $t("NewsEvents.popup.Events.location.format[2]") }}</option>
                    <option value="Whatsapp number">{{ $t("NewsEvents.popup.Events.location.format[3]") }}</option>
                    <option value="in-person event address">{{ $t("NewsEvents.popup.Events.location.format[4]") }}
                    </option>
                    <option value="other way to contact">{{ $t("NewsEvents.popup.Events.location.format[5]") }}</option>
                  </select>
                </td>
              </tr>
           <span v-show="event_location != ''">
              {{ $t("NewsEvents.popup.Events.location.enter") }} {{ event_location }}
              <br />
              <textarea rows="2" cols="33" v-model="event_location_detail"></textarea>
              <br>
              {{ $t("NewsEvents.popup.Events.location.detail") }}:
              <textarea rows="2" cols="33" v-model="event_location_detail_hybrid"></textarea>
              <br />
            </span>  -->
            <!-- <div id="event_date">
              <VueDatePicker v-model="eventDateSelected" :min-date="minDate" range fullscreen-mobile />
            </div> -->

            <!-- <div id="event_time">
              <vue-timepicker v-model="eventTimeSelected_start" format="hh:mm a" close-on-complete manual-input>
              </vue-timepicker>
              to
              <vue-timepicker v-model="eventTimeSelected_end" format="hh:mm a" close-on-complete manual-input>
              </vue-timepicker>
              <ul v-if="event_times[0].start != ''">
                <li v-for="(time, index) in event_times" :key="index">
                  {{ time.start }} {{ time.end }}
                </li>
              </ul>
              <button id="add_event_times" @click="addEventtimes()">
                Add another time <i class="fa fa-plus"></i>
              </button>
            </div>
            <br /> -->
          </div>
          <!-- signup sheet -->
          <div
            id="joblevel"
            style="background-color: #f4892f; padding: 15px; width: 100%"
          >
            <h3>Professional Function</h3>
            Job Function
            <br />
            <select
              name="jobFunction"
              id="jobFunction"
              v-model="eventpop.event_job_function"
            >
              <option value="Accounting">Accounting</option>
              <option value="Admin & HR, Banking / Finance">
                Admin & HR, Banking / Finance
              </option>
              <option value="Beauty Care / Health">Beauty Care / Health</option>
              <option value="Building & Construction">
                Building & Construction
              </option>
              <option value="Design">Design</option>
              <option value="E-Commerce">E-Commerce</option>
              <option value="Education">Education</option>
              <option value="Hospitality / F&B">Hospitality / F&B</option>
              <option value="Information Technology">
                Information Technology
              </option>
              <option value="Insurance">Insurance</option>
              <option value="Management">Management</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Marketing / Public Relations">
                Marketing / Public Relations
              </option>
              <option value="Media / Advertising">Media / Advertising</option>
              <option value="Medical Servcie">Medical Servcie</option>
              <option value="Merchandising & Purchasing">
                Merchandising & Purchasing
              </option>
              <option value="Professional Service">Professional Service</option>
              <option value="Property / Rental Estate">
                Property / Rental Estate
              </option>
              <option value="Public Sector / Civil Service">
                Public Sector / Civil Service
              </option>
              <option value="Sales, CS & Business Development">
                Sales, CS & Business Development
              </option>
              <option value="Science / R&D">Science / R&D</option>
              <option value="Transportation & Logistics">
                Transportation & Logistics
              </option>
              <option value="A self-employed Specialist">
                A self-employed Specialist
              </option>
              <option value="A Specialist working for an organization">
                A Specialist working for an organization
              </option>
              <option value="Parent/Care-taker of a Child">
                Parent/Care-taker of a Child
              </option>
              <option value="Primary Care Physician">
                Primary Care Physician
              </option>
              <option value="Other">Other</option>
            </select>
            <br />
            Job Title
            <br />
            <input type="text" v-model="eventpop.event_job_title" />
            <br />
            Job Level*
            <br />
            <select
              name="jobLevel"
              id="jobLevel"
              required
              v-model="eventpop.event_job_level"
            >
              <option value="Executive or senior management(C-suite)">
                Executive or senior management(C-suite)
              </option>
              <option value="Middle management(director, general manager)">
                Middle management(director, general manager)
              </option>
              <option
                value="First-level management(assistant manager, manager, senior manager)"
              >
                First-level management(assistant manager, manager, senior
                manager)
              </option>
              <option value="Intermediate or experienced(senior staff)">
                Intermediate or experienced(senior staff)
              </option>
              <option value="Entry-level">Entry-level</option>
              <option value="Private Party">Private Party</option>
            </select>
          </div>
          <div>
            <table id="itemTable">
              <tr class="itemRow">
                <td class="itemCell" style="width: 60%">Item Name</td>
                <td class="itemCell" style="width: 20%">Quantity</td>
                <td class="itemCell" style="width: 20%">Units</td>
              </tr>
              <tr class="itemRow">
                <td class="itemCell itemName" style="width: 60%">
                  <ul style="list-style-type: none; padding: 20px">
                    <li v-for="item in items" :key="item.name">
                      <input
                        type="checkbox"
                        v-if="item.name != ''"
                        v-model="item.checked"
                      />
                      {{ item.name }}
                    </li>
                  </ul>
                </td>
                <td class="itemCell itemQuantity" style="width: 20%">
                  <ul style="list-style-type: none">
                    <li v-for="(item, index) in items" :key="index">
                      {{ item.amount }}
                    </li>
                  </ul>
                </td>
                <td class="itemCell itemUnit" style="width: 20%">
                  <ul style="list-style-type: none">
                    <li v-for="(item, index) in items" :key="index">
                      {{ item.unit }}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr class="itemRow">
                <td class="itemCell" colspan="3">
                  <input
                    style="height: 25px"
                    placeholder="item name"
                    v-model="items[itemID].name"
                  />
                  <input
                    style="height: 25px"
                    placeholder="quantity"
                    v-model="items[itemID].amount"
                  />
                  <select
                    style="height: 25px"
                    name="unit"
                    id="unit"
                    v-model="items[itemID].unit"
                  >
                    <option value="pcs">pc(s)</option>
                    <option value="kg">kg</option>
                    <option value="g">g</option>
                    <option value="lbs">lb(s)</option>
                    <option value="ozs">oz(s)</option>
                    <option value="dozens">dozen(s)</option>
                    <option value="bottles">bottle(s)</option>
                  </select>
                  <button style="height: 25px" @click="addItem">
                    Add New Item
                  </button>
                  <button
                    style="background-color: red; height: 25px"
                    @click="removeSelectedItems"
                  >
                    Remove Selected Items
                  </button>
                  <!-- <button @click="addItem()">ADD</button> -->
                </td>
              </tr>
            </table>
          </div>

          <button
            type="button"
            style="background-color: #8cb7df; float: left"
            class="btn btn-lg"
            id="create_event"
            @click="appendEvent($event)"
          >
            {{ $t("NewsEvents.popup.Events.confirm") }}
          </button>
        </div>
      </b-modal>
      <br /><br />
      <!-- add guest -->
      <!-- <b-modal id="modal-4" title="Guest Detail">
        <div class="my-4">
          Firstname: <input type="text" v-model="guests_firstname" />
          <br />
          Lastname: <input type="text" v-model="guests_lastname" />
          <br />
          Email: <input type="text" v-model="guests_email" />
          <br />
          <button id="create_guests" @click="appendGuests($event)">Add guest</button>
        </div>
      </b-modal> -->
      <!-- detail -->

      <br />
    </div>
  </div>
</template>

<script>
import axios from "../axios";
// https://vue-datepicker.netlify.app
// date picker
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";

// https://www.npmjs.com/package/vue2-timepicker
// time picker
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";

// https://www.telerik.com/kendo-vue-ui/components/datemath/timezones/
// timezone
import { timezoneNames, ZonedDate } from "@progress/kendo-date-math";
import "@progress/kendo-date-math/tz/all";

// https://quilljs.com
// rich text editor
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "quill-vuejs";

// multiselect
import Multiselect from "vue-multiselect";
// country city select
import vueCountriesCities from "vue-countries-cities";

export default {
  data: function () {
    return {
      // calendar pick
      calendarDate: new Date(),
      calendarEvents: [],

      // mode control
      participant_mode: true,
      user_mode: false,
      admin_mode: false,

      // filter
      show_news: true,
      show_events: true,
      isCollapsed: true,
      Filter: "&#8801;",
      filter_start_date: null,
      filter_end_date: null,
      filter_age_start: 0,
      filter_age_end: 0,
      filter_for_all_age: true,
      filter_format: "",
      filter_geo_area: "",
      filter_industry: [],
      industries: [
        "Healthcare",
        "Education",
        "Gross Motor-Skills",
        "Fine Motor-Skills",
        "Speech & Language",
        "Mental Health",
        "Emotional Intelligence",
        "Literacy",
      ],
      filter_class: [],
      classes: [
        "Screening/Mini-Test",
        "Assessment",
        "Intervention",
        "Product",
        "Service",
        "Other",
      ],
      filter_price_min: 0,
      filter_price_max: 0,
      filter_free_event_only: true,
      filter_country: "",
      filter_city: "",

      // news popup
      newspop: {
        news_title: "",
        news_subtitle: "",
        news_format: "",
        news_links: "",
        news_region: "",
        news_class: [],
        news_classes: [
          "Screening/Mini-Test",
          "Assessment",
          "Intervention",
          "Product",
          "Service",
          "Other",
        ],
        news_age_start: 0,
        news_age_end: 0,
        news_for_all_age: false,
        news_industry: [],
        news_industries: [
          "Healthcare",
          "Education",
          "Gross Motor-Skills",
          "Fine Motor-Skills",
          "Speech & Language",
          "Mental Health",
          "Emotional Intelligence",
          "Literacy",
        ],
        news_description: "",
      },

      // event popup
      eventpop: {
        event_title: "",
        event_subtitle: "",
        event_region: "",
        event_class: [],
        event_classes: [
          "Screening/Mini-Test",
          "Assessment",
          "Intervention",
          "Product",
          "Service",
          "Other",
        ],
        event_age_start: 0,
        event_age_end: 0,
        event_for_all_age: false,
        event_industry: [],
        event_industries: [
          "Healthcare",
          "Education",
          "Gross Motor-Skills",
          "Fine Motor-Skills",
          "Speech & Language",
          "Mental Health",
          "Emotional Intelligence",
          "Literacy",
        ],
        event_description: "",
        event_start_date: null,
        event_end_date: null,
        event_meeting_type: "",
        event_country: "",
        event_city: "",
        event_sponsored: "",
        event_time_start: "",
        event_time_end: "",
        event_all_day: false,
        event_number_expected: 0,
        event_cost: 0,
        event_currency: "",
        event_free_cost: false,
        event_job_title: "",
        event_job_level: "",
        event_job_function: "",
      },

      // upload img
      imageUrl: null,
      docUrl: null,
      // guest
      guest_id: 1,
      guests_firstname: "",
      guests_lastname: "",
      guests_email: "",
      // timezone
      timezone: "US/Pacific",
      date: null,
      result: null,
      timezones: null,
      interval: null,
      // signup sheet TODO
      items: [{ name: "", amount: "", unit: "", checked: false }],
      itemID: 0,
      signupPeople: null,
      // role TODO
      role_designation: null,
      // location at right column TODO
      right_column_location: "",
      right_column_location_detail: "",
      right_column_location_detail_hybrid: "",
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
        theme: "snow",
      },

      // get all news and events from DB
      old_news: [],
      old_events: [],
      // event participants
      participant_email: "",
      participant_firstname: "",
      participant_lastname: "",
      // final filter
      final_filter: [],
      date_filter: [],
      age_filter: [],
      format_filter: [],
      geo_filter: [],
      industry_filter: [],
      class_filter: [],
      location_filter: [],
      price_filter: [],
    };
  },
  components: {
    Multiselect,
    VueDatePicker,
    VueTimepicker,
    quillEditor,
    vueCountriesCities,
  },
  watch: {
    // create news->all age->update age range
    newsAllAge(newValue) {
      if (newValue) {
        this.newspop.news_age_start = 0;
        this.newspop.news_age_end = 999;
      }
    },
    // create events->all age->update age range
    eventAllAge(newValue) {
      if (newValue) {
        this.eventpop.event_age_start = 0;
        this.eventpop.event_age_end = 999;
      }
    },
    // create events-> free->update cost
    eventFree(newValue) {
      if (newValue) {
        this.eventpop.event_cost = 0;
      }
    },
    // create events->all day->update time range
    eventAllDay(newValue) {
      if (newValue) {
        this.eventpop.event_time_start = "";
        this.eventpop.event_time_end = "";
      }
    },
  },
  computed: {
    registeredEvents() {
      return this.$store.state.eventsSignup;
    },

    // get username
    loggedUsername() {
      var username = this.$store.getters.getCurrentUser;
      if (username == null) {
        return "";
      }
      var userLevel = this.$store.getters.getUserLevel;
      // seting specific username as admin
      if (username == "admin1" || userLevel == "Admin") {
        this.admin();
      } else if (userLevel == "Registered User") {
        this.user();
      } else {
        this.participant();
      }
      return username;
    },
    // create news->all age
    newsAllAge() {
      return this.newspop.news_for_all_age;
    },
    // create events->all age
    eventAllAge() {
      return this.eventpop.event_for_all_age;
    },
    // create events-> free
    eventFree() {
      return this.eventpop.event_free_cost;
    },
    // create events->all day
    eventAllDay() {
      return this.eventpop.event_all_day;
    },
  },

  mounted() {
    // start timezone tick
    this.timezoneTick();
    // get all news from DB
    this.getNews();
    // get all events from DB
    this.getEvents();
  },

  //   timezone
  beforeDestroy: function () {
    clearInterval(this.interval);
  },
  methods: {
    // timezone, DO NOT CHANGE
    timezoneTick() {
      this.timezones = timezoneNames()
        .filter((l) => l.indexOf("/") > -1)
        .sort((a, b) => a.localeCompare(b));
      this.tick();
      this.interval = setInterval(() => this.tick(), 1000);
    },
    tick: function () {
      const date = new Date();
      const result = ZonedDate.fromLocalDate(date, this.timezone);
      this.date = date;
      this.result = result;
    },
    handleTimezoneChange: function (event) {
      this.timezone = event.target.value;
    },

    // three modes: admin can create both news and events; participants can add events only; user cannot do any.
    participant() {
      this.participant_mode = true;
      this.user_mode = false;
      this.admin_mode = false;
    },
    user() {
      this.user_mode = true;
      this.participant_mode = false;
      this.admin_mode = false;
    },
    admin() {
      this.admin_mode = true;
      this.participant_mode = false;
      this.user_mode = false;
    },

    // Function to find intersection
    findIntersection(...arrays) {
      return arrays.reduce((acc, currentArray) =>
        acc.filter((x) => currentArray.includes(x))
      );
    },
    // update news and events filter
    // Idea: for each field, find needed news/events; and find final ones by intersection.
    update() {
      // update all related data with these functions
      this.updateDate();
      this.updateAge();
      this.updateFormat();
      this.updateGeo();
      this.updateIndustry();
      this.updateClass();
      this.updateCountryCity();
      this.updatePrice();
      // defaultList for all news and events
      var defaultList = [];
      if (this.show_news == true) {
        for (var a = 0; a < this.old_news.length; a++) {
          defaultList.push(parseInt(this.old_news[a].Timestamp));
        }
      }
      if (this.show_events == true) {
        for (var b = 0; b < this.old_events.length; b++) {
          defaultList.push(parseInt(this.old_events[b].Timestamp));
        }
      }
      // make final_filter the intersection of all filters
      this.final_filter = this.findIntersection(
        defaultList,
        this.date_filter,
        this.age_filter,
        this.format_filter,
        this.geo_filter,
        this.industry_filter,
        this.class_filter,
        this.location_filter,
        this.price_filter
      );
      //check each news and events and display if needed.
      for (var i = 0; i < this.old_news.length; i++) {
        if (this.final_filter.includes(parseInt(this.old_news[i].Timestamp))) {
          this.old_news[i].isHidden = false;
        } else {
          this.old_news[i].isHidden = true;
        }
      }
      for (var j = 0; j < this.old_events.length; j++) {
        if (
          this.final_filter.includes(parseInt(this.old_events[j].Timestamp))
        ) {
          this.old_events[j].isHidden = false;
        } else {
          this.old_events[j].isHidden = true;
        }
      }
      // clean data for next round update
      this.date_filter = [];
      this.age_filter = [];
      this.format_filter = [];
      this.geo_filter = [];
      this.industry_filter = [];
      this.class_filter = [];
      this.location_filter = [];
      this.price_filter = [];
    },
    // update by date, check "this.date_filter" for result
    updateDate() {
      // if not used, return all news and events
      if (this.filter_start_date == null || this.filter_end_date == null) {
        for (var a = 0; a < this.old_news.length; a++) {
          this.date_filter.push(parseInt(this.old_news[a].Timestamp));
        }
        for (var b = 0; b < this.old_events.length; b++) {
          this.date_filter.push(parseInt(this.old_events[b].Timestamp));
        }
        return;
      }
      // get filter date range
      var startDate = new Date(this.filter_start_date).getTime();
      var endDate = new Date(this.filter_end_date).getTime();
      // find news created within filter range
      for (var i = 0; i < this.old_news.length; i++) {
        var newsDate = parseInt(this.old_news[i].Timestamp);
        if (startDate < newsDate && endDate > newsDate) {
          this.date_filter.push(newsDate);
        }
      }
      // find events scheduled within filter time range, but also push timestamp
      for (var j = 0; j < this.old_events.length; j++) {
        var eventStart = new Date(this.old_events[j].DateStart).getTime();
        var eventEnd = new Date(this.old_events[j].DateEnd).getTime();
        if (startDate < eventStart && endDate > eventEnd) {
          var eventDate = parseInt(this.old_events[j].Timestamp);
          this.date_filter.push(eventDate);
        }
      }
    },
    // update by age, check "this.age_filter" for result
    updateAge() {
      if (this.filter_for_all_age == true) {
        this.filter_age_start = 0;
        this.filter_age_end = Number.MAX_SAFE_INTEGER;
      }
      // get filter age range
      var fStart = parseInt(this.filter_age_start);
      var fEnd = parseInt(this.filter_age_end);
      // find news whose age ranges within filter range
      for (var i = 0; i < this.old_news.length; i++) {
        var newsDate = parseInt(this.old_news[i].Timestamp);
        var start = parseInt(this.old_news[i].AgeStart);
        var end = parseInt(this.old_news[i].AgeEnd);
        if (fStart <= start && fEnd >= end) {
          this.age_filter.push(newsDate);
        }
      }
      // find events whose age ranges within filter range
      for (var j = 0; j < this.old_events.length; j++) {
        var eventDate = parseInt(this.old_events[j].Timestamp);
        start = parseInt(this.old_events[j].AgeStart);
        end = parseInt(this.old_events[j].AgeEnd);
        if (fStart <= start && fEnd >= end) {
          this.age_filter.push(eventDate);
        }
      }
    },
    // update by format, check "this.format_filter" for result(NEWS ONLY)
    updateFormat() {
      // get filter format
      const filterFormat = this.filter_format;
      if (filterFormat == "") {
        for (var a = 0; a < this.old_news.length; a++) {
          this.format_filter.push(parseInt(this.old_news[a].Timestamp));
        }
        for (var b = 0; b < this.old_events.length; b++) {
          this.format_filter.push(parseInt(this.old_events[b].Timestamp));
        }
        return;
      }
      // find news of same format
      for (var i = 0; i < this.old_news.length; i++) {
        var newsDate = parseInt(this.old_news[i].Timestamp);
        var newsFormat = this.old_news[i].Format;
        if (filterFormat === newsFormat) {
          this.format_filter.push(newsDate);
        }
      }
    },
    // update by region, check "this.geo_filter" for result
    updateGeo() {
      // get filter geographical area
      const filterGeo = this.filter_geo_area;
      if (filterGeo == "") {
        for (var a = 0; a < this.old_news.length; a++) {
          this.geo_filter.push(parseInt(this.old_news[a].Timestamp));
        }
        for (var b = 0; b < this.old_events.length; b++) {
          this.geo_filter.push(parseInt(this.old_events[b].Timestamp));
        }
        return;
      }
      // find news of same geo
      for (var i = 0; i < this.old_news.length; i++) {
        var newsDate = parseInt(this.old_news[i].Timestamp);
        var newsGeo = this.old_news[i].Region;
        if (filterGeo === newsGeo) {
          this.geo_filter.push(newsDate);
        }
      }
      // find events of same geo
      for (var j = 0; j < this.old_events.length; j++) {
        var eventDate = parseInt(this.old_events[j].Timestamp);
        var eventGeo = this.old_events[j].Region;
        if (filterGeo === eventGeo) {
          this.geo_filter.push(eventDate);
        }
      }
    },
    // update by industry, check "this.industry_filter" for result
    updateIndustry() {
      if (this.filter_industry.length == 0) {
        for (var a = 0; a < this.old_news.length; a++) {
          this.industry_filter.push(parseInt(this.old_news[a].Timestamp));
        }
        for (var b = 0; b < this.old_events.length; b++) {
          this.industry_filter.push(parseInt(this.old_events[b].Timestamp));
        }
        return;
      }
      // for all filter industry
      for (var index = 0; index < this.filter_industry.length; index++) {
        const industry = this.filter_industry[index];
        // find news of same geo
        for (var i = 0; i < this.old_news.length; i++) {
          var newsDate = parseInt(this.old_news[i].Timestamp);
          var newsIndustry = JSON.parse(this.old_news[i].Industry);
          if (
            newsIndustry.includes(industry) &&
            this.industry_filter.includes(newsDate) == false
          ) {
            this.industry_filter.push(newsDate);
          }
        }
        // find events of same geo
        for (var j = 0; j < this.old_events.length; j++) {
          var eventDate = parseInt(this.old_events[j].Timestamp);
          var eventIndustry = JSON.parse(this.old_events[j].Industry);
          if (
            eventIndustry.includes(industry) &&
            this.industry_filter.includes(eventDate) == false
          ) {
            this.industry_filter.push(eventDate);
          }
        }
      }
    },
    // update by class, check "this.class_filter" for result
    updateClass() {
      if (this.filter_class.length == 0) {
        for (var a = 0; a < this.old_news.length; a++) {
          this.class_filter.push(parseInt(this.old_news[a].Timestamp));
        }
        for (var b = 0; b < this.old_events.length; b++) {
          this.class_filter.push(parseInt(this.old_events[b].Timestamp));
        }
        return;
      }
      // for all filter class
      for (var index = 0; index < this.filter_class.length; index++) {
        const Class = this.filter_class[index];
        // find news of same class
        for (var i = 0; i < this.old_news.length; i++) {
          var newsDate = parseInt(this.old_news[i].Timestamp);
          var newsClass = JSON.parse(this.old_news[i].Class);
          if (
            newsClass.includes(Class) &&
            this.class_filter.includes(newsDate) == false
          ) {
            this.class_filter.push(newsDate);
          }
        }
        // find events of same geo
        for (var j = 0; j < this.old_events.length; j++) {
          var eventDate = parseInt(this.old_events[j].Timestamp);
          var eventClass = JSON.parse(this.old_events[j].Class);
          if (
            eventClass.includes(Class) &&
            this.class_filter.includes(eventDate) == false
          ) {
            this.class_filter.push(eventDate);
          }
        }
      }
    },
    // update by class, check "this.location_filter" for result(EVENTS ONLY)
    updateCountryCity() {
      // get filter location
      const filterCountry = this.filter_country;
      const filterCity = this.filter_city;
      if (filterCountry == "" || filterCity == "") {
        for (var a = 0; a < this.old_news.length; a++) {
          this.location_filter.push(parseInt(this.old_news[a].Timestamp));
        }
        for (var b = 0; b < this.old_events.length; b++) {
          this.location_filter.push(parseInt(this.old_events[b].Timestamp));
        }
        return;
      }
      // find events of same location
      for (var j = 0; j < this.old_events.length; j++) {
        var eventDate = parseInt(this.old_events[j].Timestamp);
        var eventCountry = this.old_events[j].Country;
        var eventCity = this.old_events[j].City;
        if (filterCountry === eventCountry && filterCity === eventCity) {
          this.location_filter.push(eventDate);
        }
      }
    },
    // update by price, check "this.price_filter" for result(EVENTS ONLY)
    updatePrice() {
      if (this.filter_free_event_only == true) {
        for (var a = 0; a < this.old_news.length; a++) {
          this.price_filter.push(parseInt(this.old_news[a].Timestamp));
        }
        for (var b = 0; b < this.old_events.length; b++) {
          this.price_filter.push(parseInt(this.old_events[b].Timestamp));
        }
        return;
      }
      // get filter price range
      var min_filter = this.filter_price_min;
      var max_filter = this.filter_price_max;
      // find events whose price ranges within filter range
      for (var j = 0; j < this.old_events.length; j++) {
        var eventDate = parseInt(this.old_events[j].Timestamp);
        var cost = parseInt(this.old_events[j].Cost);
        if (min_filter <= cost && max_filter >= cost) {
          this.price_filter.push(eventDate);
        }
      }
    },

    // Calendar: get picked date and filter events including the date
    eventDatePicker() {
      this.calendarEvents = [];
      var today = new Date(this.calendarDate).getTime();
      for (var i = 0; i < this.old_events.length; i++) {
        var e = this.old_events[i];
        var startDate = new Date(e.DateStart).getTime();
        var endDate = new Date(e.DateEnd).getTime();
        if (startDate < today && today < endDate) {
          this.calendarEvents.push(this.old_events[i]);
        }
      }
    },
    allowedDates(date) {
      var today = new Date(date).getTime();
      for (var i = 0; i < this.old_events.length; i++) {
        var e = this.old_events[i];
        var startDate = new Date(e.DateStart).getTime();
        var endDate = new Date(e.DateEnd).getTime();
        if (startDate < today && today < endDate) {
          return date;
        }
      }
    },

    // append news to database and refresh page
    appendNews(event) {
      // check valid news
      if (
        this.newspop.news_title == "" ||
        this.newspop.news_subtitle == "" ||
        this.newspop.news_industry == [] ||
        this.newspop.news_description == ""
      ) {
        alert("Please enter Title and Description before creating news");
        event.preventDefault();
        return;
      }
      // generate and save news
      var timestamp = new Date().getTime().toString();
      const formData = new FormData();
      formData.append("title", this.newspop.news_title);
      formData.append("subtitle", this.newspop.news_subtitle);
      formData.append("format", this.newspop.news_format);
      formData.append("link", this.newspop.news_links);
      formData.append("region", this.newspop.news_region);
      formData.append("class", JSON.stringify(this.newspop.news_class));
      formData.append("ageStart", this.newspop.news_age_start);
      formData.append("ageEnd", this.newspop.news_age_end);
      formData.append("allAge", this.newspop.news_for_all_age);
      formData.append("industry", JSON.stringify(this.newspop.news_industry));
      formData.append("description", this.newspop.news_description);
      formData.append("img", this.imageUrl);
      formData.append("doc", this.docUrl);
      formData.append("timestamp", timestamp);
      formData.append("likes", 0);
      formData.append("likedUsers", JSON.stringify([]));

      // call this.getNews() to refresh
      axios
        .post("api/create/news", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          // console.log(response);
          this.getNews();
        })
        .catch((error) => {
          console.log(error);
        });
      // clear popup input
      this.clearOnModalHiddenNews();
    },
    // click news bar->trigger this function->show/hide more news details
    openToggleNews(index) {
      var box = document.getElementById("openToggleNews" + index);
      box.style.display = box.style.display == "block" ? "none" : "block";
    },
    // clear input
    clearOnModalHiddenNews() {
      this.newspop.news_title = "";
      this.newspop.news_subtitle = "";
      this.newspop.news_format = "";
      this.newspop.news_links = "";
      this.newspop.news_region = "";
      this.newspop.news_class = [];
      this.newspop.news_age_start = 0;
      this.newspop.news_age_end = 0;
      this.newspop.news_for_all_age = false;
      this.newspop.news_industry = [];
      this.newspop.news_description = "";
      this.imageUrl = null;
      this.docUrl = null;
    },

    // append events to database and refresh page
    appendEvent(event) {
      // check valid events
      if (
        this.eventpop.event_title == "" ||
        this.eventpop.event_subtitle == "" ||
        this.eventpop.event_industry == [] ||
        this.eventpop.event_start_date == null ||
        this.eventpop.event_end_date == null ||
        this.eventpop.event_meeting_type == "" ||
        this.eventpop.event_description == ""
      ) {
        alert("Please enter required information before creating an event");
        event.preventDefault();
        return;
      }
      if (this.eventpop.event_meeting_type == "inPerson") {
        if (
          this.eventpop.event_country == "" ||
          this.eventpop.event_city == ""
        ) {
          alert("Please enter country and city for in-person event");
          event.preventDefault();
          return;
        }
      }
      // generate and save event
      var timestamp = new Date().getTime().toString();
      const formData = new FormData();
      formData.append("title", this.eventpop.event_title);
      formData.append("subtitle", this.eventpop.event_subtitle);
      formData.append("region", this.eventpop.event_region);
      formData.append("class", JSON.stringify(this.eventpop.event_class));
      formData.append("ageStart", this.eventpop.event_age_start);
      formData.append("ageEnd", this.eventpop.event_age_end);
      formData.append("allAge", this.eventpop.event_for_all_age);
      formData.append("industry", JSON.stringify(this.eventpop.event_industry));
      formData.append("description", this.eventpop.event_description);
      formData.append("img", this.imageUrl);
      formData.append("doc", this.docUrl);
      formData.append("timestamp", timestamp);
      formData.append("dateStart", this.eventpop.event_start_date);
      formData.append("dateEnd", this.eventpop.event_end_date);
      formData.append("allDay", this.eventpop.event_all_day);
      formData.append("meetingType", this.eventpop.event_meeting_type);
      formData.append("country", this.eventpop.event_country);
      formData.append("city", this.eventpop.event_city);
      formData.append("sponsor", this.eventpop.event_sponsored);
      formData.append("timeStart", this.eventpop.event_time_start);
      formData.append("timeEnd", this.eventpop.event_time_end);
      formData.append("timezone", this.timezone);
      formData.append("numExpect", this.eventpop.event_number_expected);
      formData.append("cost", this.eventpop.event_cost);
      formData.append("currency", this.eventpop.event_currency);
      formData.append("noCost", this.eventpop.event_free_cost);
      formData.append("jobFunction", this.eventpop.event_job_function);
      formData.append("jobLevel", this.eventpop.event_job_level);
      formData.append("jobTitle", this.eventpop.event_job_title);
      formData.append("likes", 0);
      formData.append("likedUsers", JSON.stringify([]));
      if (this.items[0].name == "") {
        formData.append("itemList", JSON.stringify([]));
      } else {
        formData.append("itemList", JSON.stringify(this.items));
      }
      // call this.getEvents() to refresh
      axios
        .post("api/create/event", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          // console.log(response);
          this.getEvents();
        })
        .catch((error) => {
          console.log(error);
        });
      // clear popup input
      this.clearOnModalHiddenEvents();
    },
    // click events bar->trigger this function->show/hide more events details
    openToggleEvents(index) {
      var box = document.getElementById("openToggleEvents" + index);
      box.style.display = box.style.display == "block" ? "none" : "block";
    },
    // clear input
    clearOnModalHiddenEvents() {
      this.eventpop.event_title = "";
      this.eventpop.event_subtitle = "";
      this.eventpop.event_region = "";
      this.eventpop.event_class = [];
      this.eventpop.event_age_start = 0;
      this.eventpop.event_age_end = 0;
      this.eventpop.event_for_all_age = false;
      this.eventpop.event_industry = [];
      this.eventpop.event_description = "";
      this.eventpop.event_start_date = null;
      this.eventpop.event_end_date = null;
      this.eventpop.event_meeting_type = "";
      this.eventpop.event_country = "";
      this.eventpop.event_city = "";
      this.eventpop.event_sponsored = "";
      this.eventpop.event_time_start = "";
      this.eventpop.event_time_end = "";
      this.eventpop.event_all_day = false;
      this.eventpop.event_number_expected = 0;
      this.eventpop.event_cost = 0;
      this.eventpop.event_currency = "";
      this.eventpop.event_free_cost = false;
      this.imageUrl = null;
      this.docUrl = null;
      this.items = [{ name: "", amount: "", unit: "" }];
      this.itemID = 0;
    },

    // read image for later save
    loadFile(event) {
      this.imageUrl = event.target.files[0];
    },
    // read doc for later save
    loadDoc(event) {
      this.docUrl = event.target.files[0];
    },
    // event creation->itemlist->remove item
    removeSelectedItems() {
      var temp = [];
      for (var i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if (!item.checked) {
          // console.log(item);
          temp.push(item);
        }
      }
      if (temp.length == this.items.length) {
        return;
      }
      this.items = temp;
      this.itemID = this.items.length;
      this.items.push({ name: "", amount: "", unit: "", checked: false });
    },
    // event creation->itemlist->add item
    addItem() {
      var cur = this.items[this.itemID];
      if (cur.name != "" && cur.amount != "" && cur.unit != "") {
        this.itemID++;
        this.items.push({ name: "", amount: "", unit: "", checked: false });
      }
    },

    // access news from DB
    getNews() {
      axios
        .get("api/news")
        .then((response) => {
          this.old_news = response.data
            .reverse()
            .map((item) => ({ ...item, isHidden: false }));
          // console.log(this.old_news);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // access events from DB
    getEvents() {
      axios
        .get("api/event")
        .then((response) => {
          this.old_events = response.data
            .reverse()
            .map((item) => ({ ...item, isHidden: false }));
          // console.log(this.old_events);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // upvote news
    likeNews(newsID) {
      if (this.loggedUsername == "") {
        alert("Please login to upvote");
        return;
      }
      axios
        .post("api/likeNews", { newsID: newsID, user: this.loggedUsername })
        .then(() => {
          this.getNews();
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .post("api/likeNewsUser", {
          newsID: newsID,
          user: this.loggedUsername,
        })
        .then(() => {
          console.log("good");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // upvote events
    likeEvent(eventID) {
      if (this.loggedUsername == "") {
        alert("Please login to upvote");
        return;
      }
      axios
        .post("api/likeEvent", { eventID: eventID, user: this.loggedUsername })
        .then(() => {
          this.getEvents();
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .post("api/likeEventUser", {
          eventID: eventID,
          user: this.loggedUsername,
        })
        .then(() => {
          console.log("good");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    isInRegisteredEvents(number) {
      // console.log(number);
      return this.registeredEvents.includes(parseInt(number, 10));
    },

    // decrypt img/doc
    base64ToBlob(base64, mimeType) {
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: mimeType });
    },
    // download doc file
    downloadFile(base64Data, fileExtension) {
      const mimeTypeMap = {
        ".docx":
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ".pdf": "application/pdf",
        ".doc": "application/msword",
        ".xlsx":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ".xls": "application/vnd.ms-excel",
        ".pptx":
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        ".ppt": "application/vnd.ms-powerpoint",
        ".txt": "text/plain",
        ".csv": "text/csv",
        ".html": "text/html",
        ".htm": "text/html",
        ".js": "text/javascript",
        ".json": "application/json",
        ".xml": "application/xml",
        ".bmp": "image/bmp",
        ".svg": "image/svg+xml",
        ".mp3": "audio/mpeg",
        ".wav": "audio/wav",
        ".mp4": "video/mp4",
        ".mov": "video/quicktime",
        ".avi": "video/x-msvideo",
        // Add more MIME types as needed
      };
      const mimeType =
        mimeTypeMap[fileExtension.toLowerCase()] || "application/octet-stream";
      const blob = this.base64ToBlob(base64Data, mimeType);
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "example" + mimeType;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // signup event modal
    openModal(modalId) {
      this.$root.$emit("bv::show::modal", modalId);
    },
    // signup for event->send email confirmation and save data
    sendTransEmail(email, timestamp, eventboxId) {
      // send transactional email
      axios
        .post("api/send-trans-email", {
          email: email,
          firstname: this.participant_firstname,
          lastname: this.participant_lastname,
        })
        .then((response) => {
          console.log("Email sent successfully:", response.data);
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });

      // save data
      axios
        .post("api/saveEventParticipant", {
          email: email,
          firstname: this.participant_firstname,
          lastname: this.participant_lastname,
          timestamp: timestamp,
        })
        .then((response) => {
          console.log("Data saved successfully:", response.data);
          alert("You have successfully signed up this event");
          // close popup, clear data
          this.$bvModal.hide(eventboxId);
          this.participant_firstname = "";
          this.participant_lastname = "";
        })
        .catch((error) => {
          console.error("Error saving participant:", error);
        });

      // save data in user
      axios
        .post("api/saveUserEventParticipant", {
          email: email,
          timestamp: timestamp,
        })
        .then((response) => {
          console.log("Data saved successfully:", response.data);
        })
        .catch((error) => {
          console.error("Error saving participant:", error);
        });
    },
    // basic date format
    formatDate(dateString) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const date = new Date(dateString);
      const monthName = monthNames[date.getMonth()];
      return `${monthName} ${date.getDate()}, ${date.getFullYear()}`;
    },
    filterInput(event) {
      let regex = /[^0-9]/g;
      if (regex.test(event.key)) {
        event.preventDefault();
      }
    },
    // TODO
    appendGuests(event) {
      // check valid
      if (
        this.guests_firstname == "" ||
        this.guests_lastname == "" ||
        this.guests_email == ""
      ) {
        alert("Please complete the form before adding a guest");
        event.preventDefault();
        return;
      }
      // append guest box to its parent
      var box = document.createElement("div");
      box.setAttribute("class", "appendGuests");

      var parent = document.getElementById("guests_column");
      parent.appendChild(box);
      // append input content to guests box
      box.innerHTML +=
        "<span>" +
        this.guests_firstname +
        "," +
        this.guests_lastname +
        " " +
        this.guests_email +
        "</span>";
      // set id and bind remove function
      var box_id = this.guest_id;
      box.setAttribute("id", box_id);
      box.innerHTML +=
        "<button id='" + this.guest_id + "'>remove event</button>";
      document
        .getElementById(this.guest_id)
        .addEventListener("click", this.removeGuest(box_id), false);
      this.guest_id++;
      box.innerHTML += "<br>";
      // end of adding guests
      this.closeGuests();
      this.clearGuestsData();
    },
    removeGuest(id) {
      return function () {
        if (confirm("remove?")) {
          var div = document.getElementById(id);
          div.parentNode.removeChild(div);
        }
      };
    },
    clearGuestsData() {
      this.guests_lastname = "";
      this.guests_firstname = "";
      this.guests_email = "";
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scope>
#toggle_bar {
  border-style: outset;
  width: 80%;
  overflow: auto;
}

#my-collapse {
  width: 80%;
}

.center {
  top: 50%;
  transform: translate(0, 50%);
}

.form-check-input {
  transform: translate(10%, 10%);
  scale: 1.2;
}

#events:after {
  content: "";
  display: table;
  clear: both;
}

#right_column {
  float: left;
  width: 30%;
  padding-left: 5%;
  height: 100%;
}

#left_column {
  float: left;
  width: 65%;
}

/* add tag */
.add_tag {
  border-radius: 9999em 9999em 9999em 9999em;
  width: auto;
  text-align: center;
}

.add_tag:focus {
  background-color: #87cefa;
}

.tags:focus {
  background-color: #87cefa;
}

.mode:focus {
  background-color: #87cefa;
}

/* appended events */
.appendEvent {
  position: relative;
  top: auto;
  width: 100%;
  display: none;
  height: auto;
  min-height: 210px;
  border: 3px solid #f4b47f;
  background-color: #f4b47f;
  border-radius: 5px;
  margin: 5px;
  padding-left: 20px;
}

/* appended news */
.appendNews {
  position: relative;
  top: 0px;
  width: 100%;
  display: none;
  height: auto;
  min-height: 210px;
  border: 3px solid #abc8e2;
  border-radius: 5px;
  background-color: #abc8e2;
  margin: 5px;
  padding-left: 20px;
}

.checkbox {
  /* position: absolute; */
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* Style the custom radio button appearance */
.customRadio {
  width: 17px;
  height: 17px;
  background-color: white !important;

  border: 2px solid black;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}

/* Style the custom radio button when it's checked */
input[type="checkbox"]:checked + .customRadio::before {
  content: "\2022";
  /* Use a bullet character or any other symbol */
  font-size: 40px;
  line-height: 16.5px;
  text-align: left;
  color: #0075ff;
  /* Use the desired checked color */
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
}

th,
td {
  padding: 10px; /* All sides */
}

#itemTable {
  /* font-family: arial, sans-serif; */
  border-collapse: collapse;
  width: 100%;
}

.itemRow {
  background-color: white;
  border: 1px solid black;
  text-align: left;
  padding: 8px;
}

.itemCell {
  background-color: white;
  border: 1px solid black;
  text-align: left;
  padding: 8px;
}

.download-btn {
  padding: 10px 20px;
  background-color: #4caf50; /* Green */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  transition-duration: 0.4s;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 5px 5px 0 rgba(0, 0, 0, 0.19);
}

.download-btn:hover {
  background-color: #45a049;
}

.material-icons {
  vertical-align: middle;
  margin-right: 8px;
}

.upvoteButton {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}
.upvoteButton:hover {
  border: none;
  background-color: white;
}
</style>
