<template>
  <div id="directInvest">
      <directInvest_HowItWorks/>
  </div>
</template>

<script>
import directInvest_HowItWorks from './directInvest_HowItWorks.vue';
export default {
  components: { directInvest_HowItWorks },
  name: 'directInvest',

  methods: {

  }
}


</script>

<style scoped>
</style>