<template>
  <div id="directInvest_Opportunities_Details_Pdf">
    <div>
      <div class="pdf">
        <a class="pdf-btn" :href=getPath() :download="download"><img class="pdfImg" src="../img/pdf_icon.png"
            alt="pdf_icon"></a>
        <span class="filename">{{ filename }}</span>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  name: 'directInvest_Opportunities_Details_Pdf',
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  props: {
    href: String,
    download: String,
    filename: String
  },
  methods: {
    getPath: function () {
      return process.env.BASE_URL + this.href
    }
  }
}

</script>

<style scoped>
.pdf {
  margin: 18px;
  cursor: pointer;
}

ul {
  list-style-type: none;
  padding-left: 20px;
}

li {
  margin: 40px 0px;

}

.filename {
  margin: 10px;
}

img:hover {
  cursor: pointer;

}

.pdfImg {
  width: 52px;

}
</style>